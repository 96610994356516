<script setup lang="ts">
    import type {Collection} from '@meekohq/lumos';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import moment from 'moment';
    import {ref} from 'vue';

    import useApi from '@/modules/app/composables/useApi';
    import __ from '@/modules/app/utils/i18n-facade';
    import route from '@/modules/legacy/libs/ziggy';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';
    import type {LegacyOrganizationType} from '@/modules/organization/utils/LegacyOrganizationType';

    import SelectInvoices from './SelectInvoices.vue';

    const props = defineProps<{
        nursery: LegacyOrganizationType;
        date: moment;
        modal: any;
    }>();

    const emit = defineEmits<{
        (e: 'updated', value: Collection): void;
    }>();

    const selectedInvoices = ref([]);
    const loading = ref(false);
    const sendToEmail = ref(false);
    const email = ref(props.nursery.email);

    const formats = [
        {
            name: 'pdf',
            title: __('billing_invoice:only_one_pdf'),
            icon: 'fa-file-pdf',
        },
        {
            name: 'pdfs',
            title: __('billing_invoice:multiple_pdf'),
            icon: 'fa-copy',
        },
        {
            name: 'zip',
            title: __('billing_invoice:compressed_folder'),
            icon: 'fa-file-archive',
        },
        {
            name: 'xlsx',
            title: __('billing_invoice:excel_file'),
            icon: 'fa-file-excel',
        },
    ];

    const selectedFormats = ref(['pdf']);

    function send() {
        if (loading.value) {
            return;
        }

        loading.value = true;

        useApi()
            .legacy.post(
                route('nurseries.invoices.sendbymail', {
                    nurseries: props.nursery.id,
                }),
                {
                    invoices: selectedInvoices.value.map(invoice => invoice.id),
                    email: sendToEmail.value ? email.value : null,
                    formats: selectedFormats.value,
                }
            )
            .then(response => {
                emit('updated', response.data);
                useNotification().success(
                    __('billing_invoice:invoices_sended_successfully', {count: selectedInvoices.value.length})
                );
            })
            .catch(error => {
                if (error && error.response && error.response.status === 422) {
                    _forEach(error.response.data.errors, value => {
                        useNotification().error(_head(value));
                    });
                } else {
                    useNotification().error(__('common:errors.generic'));
                }
            })
            .finally(() => {
                loading.value = false;
            });
    }

    function selectFormat(format) {
        selectedFormats.value = [];
        selectedFormats.value.push(format.name);
    }

    function hasFormat(format) {
        return selectedFormats.value.find(item => item === format.name);
    }
</script>

<template>
    <MModal
        :modal="modal"
        size="5xl"
    >
        <template #header-text>
            {{ __('billing_invoice:send_invoices') }}
            -
            <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
        </template>

        <SelectInvoices
            v-model:selected-invoices="selectedInvoices"
            action="send"
            :date="date"
            :nursery="nursery"
        />

        <div class="tw-mt-4 tw-px-4">
            <hr class="hr" />

            <MForm class="tw-w-full lg:tw-w-1/2 xl:tw-w-1/3">
                <MFieldset>
                    <MLabel>{{ __('common:actions.send_selection_to_colon') }}</MLabel>
                    <CButtonGroup>
                        <MButton
                            :label="__('common:each_parent')"
                            :variant="sendToEmail === false ? 'primary' : 'light'"
                            @click="sendToEmail = false"
                        />
                        <MButton
                            :label="__('common:specific_email')"
                            :variant="sendToEmail === true ? 'primary' : 'light'"
                            @click="sendToEmail = true"
                        />
                    </CButtonGroup>
                </MFieldset>
                <MFieldset v-if="sendToEmail">
                    <CInputGroup class="tw-w-full">
                        <CInputAddon>
                            <FontAwesomeIcon
                                class="tw-text-primary-500"
                                :fixed-width="true"
                                icon="fa-solid fa-envelope"
                            />
                        </CInputAddon>
                        <MInput
                            v-model="email"
                            :placeholder="__('billing_invoice:receiving_email')"
                        />
                    </CInputGroup>
                </MFieldset>
            </MForm>
        </div>

        <template v-if="sendToEmail">
            <div class="tw-mt-6 tw-px-4">
                <MHeading
                    level="h3"
                    class="tw-mb-2"
                >
                    {{ __('billing_invoice:what_is_the_desired_format_ask') }}
                </MHeading>
                <div class="row">
                    <div
                        v-for="(format, i) in formats"
                        :key="'format' + i"
                        class="col-6 col-lg-3 tw-mt-2"
                    >
                        <div
                            class="tw-cursor-pointer tw-rounded-lg tw-border tw-pb-2 tw-pt-4 tw-text-center"
                            :class="{'selected tw-bg-info-500 tw-text-white': hasFormat(format)}"
                            @click="selectFormat(format)"
                        >
                            <div>
                                <FontAwesomeIcon
                                    :icon="'fa-solid ' + format.icon"
                                    size="2x"
                                />
                            </div>
                            <div class="tw-mt-2 tw-text-sm">
                                {{ format.title }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #footer-end="{hide}">
            <MButton
                :label="__('common:actions.cancel')"
                @click="hide"
            />
            <MButton
                :loading="loading"
                variant="primary"
                @click="send"
            >
                {{ __('common:actions.send') }}
                -
                <strong class="tw-capitalize">{{ date.clone().format('MMMM Y') }}</strong>
            </MButton>
        </template>
    </MModal>
</template>
