<template>
    <div
        class="card-chart"
        :class="{settings: showSettings}"
    >
        <div class="chart-title">
            <div class="tw-mb-4 tw-ml-1 tw-flex tw-flex-row">
                <div class="tw-flex tw-items-center tw-text-base tw-font-semibold tw-uppercase">
                    <FontAwesomeIcon
                        class="tw-mr-2"
                        icon="fa-solid fa-users"
                    />
                    {{ __('common:team_one') }}
                </div>
                <div
                    class="tw-ml-auto tw-flex tw-cursor-pointer tw-flex-row tw-items-center tw-text-sm tw-font-semibold tw-uppercase"
                    @click="showSettings = !showSettings"
                >
                    {{ humanizedSelectedDate }}
                    <FontAwesomeIcon
                        class="tw-ml-2"
                        icon="fa-solid fa-pencil"
                    />
                </div>
            </div>
            <div class="actions">
                <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                    <MDatePicker
                        ref="datePicker"
                        v-model="selectedDate"
                        class="tw-col-start-2 tw-w-full"
                        format="yyyy-MM-dd"
                    />
                </div>
            </div>
        </div>
        <div class="chart-content">
            <div class="chart-container tw-px-1">
                <div class="tw-w-full">
                    <loader
                        v-if="loading"
                        light="true"
                        shadow="false"
                        size="sm"
                    />
                    <div
                        v-else-if="filteredStaffs.length"
                        class="StaffPresences__list"
                    >
                        <div
                            v-for="(staff, i) in filteredStaffs"
                            :key="'staff' + i"
                            class="StaffPresences__item"
                        >
                            <div class="StaffPresences__header">
                                <StaffAvatar
                                    class="StaffPresences__avatar"
                                    :staff-model="staff"
                                />
                                <div
                                    class="StaffPresences__name"
                                    :class="staff.attributes.gender === 'male' ? 'tw-text-boy' : 'tw-text-girl'"
                                >
                                    <router-link
                                        class="tw-text-inherit"
                                        :to="{name: 'staffs.show', params: {nursery: nursery.id, staff: staff.id}}"
                                    >
                                        {{ $fullName(staff.attributes) }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="StaffPresences__container">
                                <div class="StaffPresences__sub-container">
                                    <MTooltip
                                        v-for="(absence, i) in staff.events().value()"
                                        :key="'absence' + i"
                                        class="tw-shrink-0"
                                    >
                                        <MBadge variant="danger">
                                            <template v-if="absence.attributes.datetime_event">
                                                <template v-if="isAbsenceOnOneDay(absence)">
                                                    {{
                                                        $formatDate(
                                                            absence.attributes.datetime_event.started_at,
                                                            'HH:mm'
                                                        )
                                                    }}
                                                    -
                                                    {{
                                                        $formatDate(absence.attributes.datetime_event.ended_at, 'HH:mm')
                                                    }}
                                                </template>
                                                <template v-else>
                                                    {{
                                                        $formatDate(
                                                            absence.attributes.datetime_event.started_at,
                                                            'D/MM'
                                                        )
                                                    }}
                                                    -
                                                    {{
                                                        $formatDate(absence.attributes.datetime_event.ended_at, 'D/MM')
                                                    }}
                                                </template>
                                            </template>
                                            <template v-else>
                                                <template v-if="isAbsenceOnOneDay(absence)">
                                                    {{ __('dashboard:all_day') }}
                                                </template>
                                                <template v-else>
                                                    {{ $formatDate(absence.attributes.date_event.started_at, 'D/MM') }}
                                                    -
                                                    {{ $formatDate(absence.attributes.date_event.ended_at, 'D/MM') }}
                                                </template>
                                            </template>
                                        </MBadge>
                                        <template #content>
                                            <template v-if="absence.attributes.note">
                                                {{ absence.attributes.note }}
                                            </template>
                                            <template v-else>
                                                {{ __('common:absence') }}
                                            </template>
                                        </template>
                                    </MTooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        class="tw-p-2 tw-text-center"
                    >
                        {{ __('dashboard:staffs_no_warning') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Epoch} from '@meekohq/lumos';
    import {isNil, set} from 'lodash-es';
    import _forEach from 'lodash-es/forEach';
    import _head from 'lodash-es/head';
    import moment from 'moment';

    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import useNotification from '@/modules/meeko-ui/composables/useNotification';

    export default {
        components: {StaffAvatar},
        props: {
            nursery: {
                type: Object,
            },
        },
        data: () => ({
            staffs: [],
            date: Epoch.now().toFormat('yyyy-MM-dd'),
            selectedDate: Epoch.now().toFormat('yyyy-MM-dd'),
            selectedNursery: {},
            loading: false,
            showSettings: false,
        }),
        computed: {
            epochSelectedDate() {
                return this.selectedDate ? Epoch.parse(this.selectedDate, 'yyyy-MM-dd') : Epoch.now();
            },
            humanizedSelectedDate() {
                return this.epochSelectedDate.toLocaleString(Epoch.presets.WEEKDAY_WITH_DAY_AND_MONTH);
            },
            from() {
                return this.epochSelectedDate.startOfDay();
            },
            to() {
                return this.epochSelectedDate.endOfDay();
            },
            filteredStaffs() {
                return this.staffs?.filter(item => item.extra.hasAnomalies);
            },
        },
        watch: {
            'selectedDate'(val) {
                if (isNil(val)) {
                    return;
                }

                this.showSettings = false;
                this.$refs.datePicker.hide();
                this.date = val;
                this.getPresences();
            },
            'selectedNursery.id': {
                handler() {
                    if (this.selectedNursery && this.selectedNursery.id) {
                        this.getPresences();
                    }
                },
                deep: true,
            },
            'nursery.id': {
                handler() {
                    this.selectedNursery = this.nursery;
                },
                deep: true,
            },
        },
        mounted() {
            this.selectedNursery = this.nursery;
        },
        methods: {
            isAbsenceOnOneDay(absence) {
                let startedAtDay;
                let endedAtDay;
                if (absence.attributes.datetime_event) {
                    startedAtDay = moment(absence.attributes.datetime_event.started_at).format('DD/MM/YY');
                    endedAtDay = moment(absence.attributes.datetime_event.ended_at).format('DD/MM/YY');
                } else {
                    startedAtDay = moment(absence.attributes.date_event.started_at).format('DD/MM/YY');
                    endedAtDay = moment(absence.attributes.date_event.ended_at).format('DD/MM/YY');
                }

                return startedAtDay === endedAtDay;
            },

            async getPresences() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                try {
                    this.staffs = (
                        await StaffModel.query()
                            .whereHas('organizations', query => query.scope('active'))
                            .with(new StaffModel().events(), q1 => {
                                return q1
                                    .where('factor', -1)
                                    .scope('inRange', [moment(this.from.toJSDate()), moment(this.to.toJSDate())])
                                    .whereHas('type', q2 => {
                                        // Exclude lunch breaks from the events
                                        // Where null is used to include staffs without internal_id (Postgres dont allow null in where clause)
                                        return q2
                                            .where('internal_id', '<>', '13c23750-25a3-4cfd-8087-36a87b720a7a')
                                            .orWhereNull('internal_id');
                                    });
                            })
                            .get()
                    ).all();

                    this.staffs.forEach(staff => {
                        set(staff.extra, 'hasAnomalies', staff.events().value().count() > 0);
                    });
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    if (error && error.response && error.response.status === 422) {
                        _forEach(error.response.data.errors, value => {
                            useNotification().error(_head(value));
                        });
                    } else {
                        useNotification().error(error);
                    }
                }
            },
        },
    };
</script>

<style scoped>
    .StaffPresences__list {
        @apply tw-my-4 tw-flex tw-flex-col tw-gap-4;
    }

    .StaffPresences__item {
        @apply tw-grid tw-grid-cols-2 tw-gap-4;
    }

    .StaffPresences__header {
        @apply tw-flex tw-items-center tw-gap-2;
    }

    .StaffPresences__avatar {
        @apply tw-aspect-square tw-w-8 tw-shrink-0;
    }

    .StaffPresences__name {
        @apply tw-truncate tw-font-grandhotel tw-text-2xl tw-capitalize;
    }

    .StaffPresences__container {
        @apply tw-flex tw-items-center;
    }

    .StaffPresences__sub-container {
        @apply tw-ml-auto tw-flex tw-w-fit tw-items-center tw-gap-2 tw-overflow-x-auto;
    }
</style>
