import Bugsnag from '@bugsnag/js';
import {app} from '@meekohq/lumos';
import {ref} from 'vue';

import __ from '@/modules/app/utils/i18n-facade.ts';
import {SendRealTimeDeactivationFeedbackInputPortBinding} from '@/modules/meeko-family/domain/use-cases/SendRealTimeDeactivationFeedbackInputPort';
import useNotification from '@/modules/meeko-ui/composables/useNotification.ts';

export function useReportingSettingNotification() {
    const isSending = ref(false);
    const success = ref(false);

    async function sendRealTimeDeactivationFeedback(feedback: string) {
        isSending.value = true;
        success.value = false;

        const interactor = app(SendRealTimeDeactivationFeedbackInputPortBinding, {
            success() {
                success.value = true;
            },
            validationError() {
                useNotification().error(__('reporting:feedback_is_required'));
            },
            unexpectedError(error: Error) {
                useNotification().error(__('common:errors.generic'));
                Bugsnag.notify(error);
            },
        });

        await interactor.execute(feedback);

        isSending.value = false;

        return success.value;
    }

    return {
        isSending,
        sendRealTimeDeactivationFeedback,
    };
}
