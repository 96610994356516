<template>
    <div
        class="snow"
        :class="{storm: storm}"
        @mouseover="storm = true"
    >
        <div
            v-if="storm"
            class="tw-pointer-events-none tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full"
        >
            <div class="santa tw-h-full tw-w-full">
                <img :src="santa_sleigh" />
            </div>
        </div>
        <div
            v-if="counter"
            class="countdown tw-font-display"
        >
            {{ counter }}
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import santa_sleigh from '@/assets/images/special/santa_sleigh.gif';

    export default {
        data() {
            return {
                storm: false,
                santa_sleigh,
            };
        },

        watch: {
            storm(val) {
                if (val) {
                    setTimeout(() => {
                        this.storm = false;
                    }, 3000);
                }
            },
        },

        computed: {
            counter() {
                const checkDate = moment('2024-12-25');

                const days = checkDate.diff(moment(), 'days');
                if (days > 1) {
                    return 'J-' + days;
                } else if (days >= 0) {
                    const hours = checkDate.diff(moment(), 'hours');

                    return hours > 0 ? 'H-' + checkDate.diff(moment(), 'hours') : null;
                }

                return null;
            },
        },
    };
</script>

<style scoped>
    @keyframes santa-fly {
        0% {
            transform: translate(5vw, 10vh);
        }
        40% {
            transform: translate(20vw, 8vh);
        }
        60% {
            transform: translate(30vw, 13vh);
        }
        80% {
            opacity: 1;
        }
        100% {
            transform: translate(80vw, -15vh);
            opacity: 0;
        }
    }

    .santa {
        animation: santa-fly 2.5s linear forwards;

        @apply tw-pointer-events-none tw-absolute;
        z-index: 999;
        width: 140px;
    }

    @media (max-width: 1024px) {
        .countdown {
            display: none;
        }
    }

    .countdown {
        position: absolute;
        top: 0px;
        left: -30px;
        transform: rotate(-20deg);
        border-radius: 8px;
        background: #f9b03d;
        padding: 2px 1px;
        width: 40px;
        color: white;
        font-size: 12px;
        text-align: center;
    }

    .snow-flake {
        position: absolute;
        color: white;

        &:after {
            content: '\2744';
        }
    }

    .snowflake:nth-child(1) {
        top: -20%;
    }

    .snowflake:nth-child(2) {
        top: -40%;
    }

    .snowflake:nth-child(3) {
        top: -33%;
    }

    .snow {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        animation: snow 10s linear infinite;
        background: url('@/assets/images/special/snow4.png'), url('@/assets/images/special/snow2.png'),
            url('@/assets/images/special/snow3.png');
        width: 95%;
        height: 120%;

        &.storm {
            animation: snow 3s linear infinite;
        }
    }

    @keyframes snow {
        0% {
            background-position:
                0 0,
                0 0,
                0 0;
        }
        50% {
            background-position:
                500px 500px,
                100px 200px,
                -100px 150px;
        }
        100% {
            background-position:
                500px 1000px,
                200px 400px,
                -100px 300px;
        }
    }

    @-moz-keyframes snow {
        0% {
            background-position:
                0 0,
                0 0,
                0 0;
        }
        50% {
            background-position:
                500px 500px,
                100px 200px,
                -100px 150px;
        }
        100% {
            background-position:
                400px 1000px,
                200px 400px,
                100px 300px;
        }
    }

    @-webkit-keyframes snow {
        0% {
            background-position:
                0 0,
                0 0,
                0 0;
        }
        50% {
            background-position:
                500px 500px,
                100px 200px,
                -100px 150px;
        }
        100% {
            background-position:
                500px 1000px,
                200px 400px,
                -100px 300px;
        }
    }

    @-ms-keyframes snow {
        0% {
            background-position:
                0 0,
                0 0,
                0 0;
        }
        50% {
            background-position:
                500px 500px,
                100px 200px,
                -100px 150px;
        }
        100% {
            background-position:
                500px 1000px,
                200px 400px,
                -100px 300px;
        }
    }
</style>
