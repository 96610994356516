import {collect, MqlOperation, MqlUnprocessableEntityError, ValidationError} from '@meekohq/lumos';

import {ReportingSettingEntity} from '@/modules/meeko-family/domain/entities/ReportingSettingEntity';
import type {ReportingSettingRepositoryPort} from '@/modules/meeko-family/domain/ports/ReportingSettingRepositoryPort';

export class ReportingSettingRepositoryAdapter implements ReportingSettingRepositoryPort {
    async getReportingSettings(organizationId: string): Promise<ReportingSettingEntity[]> {
        const operation = new MqlOperation('organization/reporting-settings/get', {
            organization_id: organizationId,
        });

        const result = await operation.run();

        return collect(result.content)
            .map((setting: any) => {
                return new ReportingSettingEntity(
                    setting.id,
                    setting.account_id,
                    setting.organization_id,
                    setting.type,
                    setting.mark_next_reporting_as_visible,
                    setting.mark_next_reporting_observation_as_visible,
                    setting.real_time
                );
            })
            .toArray();
    }

    async storeReportingSettings(organizationId: string, reportingSettings: ReportingSettingEntity[]): Promise<void> {
        const params = collect(reportingSettings).map(setting => {
            return {
                account_id: setting.accountId,
                organization_id: setting.organizationId,
                type: setting.type,
                mark_next_reporting_as_visible: setting.markNextReportingAsVisible,
                mark_next_reporting_observation_as_visible: setting.markNextReportingObservationAsVisible,
                real_time: setting.realTime,
            };
        });

        try {
            const operation = new MqlOperation('organization/reporting-settings/store', {
                organization_id: organizationId,
                settings: params.toArray(),
            });

            await operation.run();
        } catch (error: Error) {
            if (error instanceof MqlUnprocessableEntityError) {
                const resultContent = error.operation.result.content as {data: any} | null | undefined;

                if (!resultContent) {
                    throw error;
                }

                throw new ValidationError(collect(resultContent.data));
            }

            throw error;
        }
    }
}
