import {collect, MqlOperation, MqlUnprocessableEntityError, ValidationError} from '@meekohq/lumos';

import type {ReportingSettingsNotifierPort} from '@/modules/meeko-family/domain/ports/ReportingSettingsNotifierPort';

export class ReportingSettingNotifierAdapter implements ReportingSettingsNotifierPort {
    async sendRealTimeDeactivationFeedback(feedback: string): Promise<void> {
        try {
            const operation = new MqlOperation('notification/reporting-settings/send-real-time-deactivation-feedback', {
                feedback,
            });

            await operation.run();
        } catch (error: Error) {
            if (error instanceof MqlUnprocessableEntityError) {
                const resultContent = error.operation.result.content as {data: any} | null | undefined;

                if (!resultContent) {
                    throw error;
                }

                throw new ValidationError(collect(resultContent.data));
            }

            throw error;
        }
    }
}
