<script setup lang="ts">
    import i18next from 'i18next';
    import _forEach from 'lodash-es/forEach';
    import {onMounted, watch} from 'vue';

    import {getNumericParserContractPresetCurrencyPerHour} from '@/modules/core/infrastructure/NumericParserContractPresets';

    const {prices, brackets, nursery} = defineProps({
        prices: {
            type: Object,
            required: true,
        },
        brackets: {
            type: Number,
            required: true,
        },
        nursery: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits<{
        (e: 'updatePrice', value: {unit: string; hours: {value: number}[]; brackets: {value: number}[][]}): void;
    }>();

    const unit = 'hour';
    const hours = [{value: 10}, {value: 20}, {value: 30}, {value: 30}];

    const parserOptionsCurrencyPerHour = getNumericParserContractPresetCurrencyPerHour(i18next.language);
    parserOptionsCurrencyPerHour.allowNegative = true;

    onMounted(() => {
        if (prices.unit !== unit) {
            reset();
        }
    });

    watch(
        () => brackets,
        () => {
            // Compare le nombre de bracket, au nombre de bracket dans le price pour ne rien faire au changement de conf
            if (brackets === prices.brackets.length) {
                return;
            }

            // A partir d'ici on est soit à l'ajout d'une tranche de revenu, soit à la suppression
            if (brackets < prices.brackets.length) {
                prices.brackets.splice(brackets, prices.brackets.length - brackets);
            } else {
                for (let i = prices.brackets.length; i < brackets; i++) {
                    const newBracket = prices.hours.map(() => ({value: 10}));
                    prices.brackets.push(newBracket);
                }
            }
        }
    );

    function addHour() {
        prices.hours.push({value: null});
        _forEach(prices.brackets, bracket => {
            bracket.push({value: 10});
        });
    }

    function subHour() {
        prices.hours.splice(hours.length - 1, 1);
        _forEach(prices.brackets, bracket => {
            bracket.splice(bracket.length - 1, 1);
        });
    }

    function reset() {
        const resettedBrackets = [];
        for (let i = 0; i < brackets; i++) {
            resettedBrackets.push([{value: 10}, {value: 10}, {value: 10}, {value: 10}]);
        }

        emit('updatePrice', {
            unit: unit,
            hours: hours,
            brackets: resettedBrackets,
        });
    }
</script>

<template>
    <div class="tw-block tw-w-full tw-overflow-x-auto">
        <MSmall class="">
            <FontAwesomeIcon
                class="tw-mr-0.5"
                icon="fa-duotone fa-info-circle"
            />
            {{ __('billing_setting:hour_per_week_explanation') }}
        </MSmall>
        <table class="tw-mb-4 tw-w-full">
            <thead>
                <tr>
                    <th scope="col" />
                    <th
                        v-for="(hour, key) in prices.hours"
                        :key="key"
                        scope="col"
                        style="min-width: 100px"
                    >
                        <CInputGroup>
                            <CInputAddon>
                                {{ key === prices.hours.length - 1 ? '>' : '≤' }}
                            </CInputAddon>
                            <MNumberInput
                                v-model="hour.value"
                                :parser-options="parserOptionsCurrencyPerHour"
                                placeholder="10"
                                class="tw-min-w-16"
                            />
                            <CInputAddon>
                                {{ __('common:hour_short') }}
                            </CInputAddon>
                        </CInputGroup>
                    </th>
                    <th
                        class="tw-whitespace-nowrap tw-text-right"
                        scope="col"
                    >
                        <MButton
                            class="tw-mr-1"
                            variant="success"
                            @click="addHour"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-plus-circle" />
                        </MButton>
                        <MButton
                            v-if="prices && prices.hours && prices.hours.length > 2"
                            variant="danger"
                            @click="subHour"
                        >
                            <FontAwesomeIcon icon="fa-solid fa-times-circle" />
                        </MButton>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(bracket, index) in prices.brackets"
                    :key="'bracket ' + index"
                >
                    <th
                        v-if="prices.brackets.length > 1"
                        class="tw-pr-2"
                        scope="row"
                    >
                        {{ __('billing_setting:t_value', {value: index + 1}) }}
                    </th>
                    <th
                        v-else
                        scope="row"
                    />
                    <th
                        v-for="(price, i) in bracket"
                        :key="'price ' + i"
                        style="min-width: 100px"
                    >
                        <CInputGroup class="tw-font-normal">
                            <MCurrencyInput
                                v-model.number="price.value"
                                :currency-symbol="nursery.currency"
                                :parser-options="parserOptionsCurrencyPerHour"
                                placeholder="9"
                                class="tw-min-w-16"
                            >
                                <template #addon>
                                    {{ __('common:currency_per_hour', {currency: nursery.currency}) }}
                                </template>
                            </MCurrencyInput>
                        </CInputGroup>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
    table th,
    table td {
        @apply tw-p-1;
    }
</style>
