<script lang="ts" setup>
    import {computed, ref} from 'vue';

    import type {ModalType} from '@/modules/app/composables/useModal';

    defineProps<{
        modal: ModalType;
        isLoading: boolean;
    }>();

    const emit = defineEmits<{
        (e: 'confirmed', feedback: string): void;
    }>();

    const feedback = ref('');

    const feedbackIsFilled = computed(() => feedback.value.trim().length > 0);
</script>

<template>
    <MModal
        :header-title="__('reporting:feedback_title')"
        :modal="modal"
        size="md"
    >
        <MForm @submit.prevent>
            <MFieldset>
                <MLabel>{{ __('reporting:feedback_label') }}*</MLabel>
                <MTextarea
                    v-model="feedback"
                    :placeholder="__('reporting:feedback_help_text')"
                    rows="4"
                />
            </MFieldset>
        </MForm>
        <template #footer-end="{hide}">
            <MButton @click="hide">
                {{ __('common:actions.cancel') }}
            </MButton>
            <MTooltip :hoverable="!feedbackIsFilled">
                <MButton
                    :disabled="!feedbackIsFilled"
                    :loading="isLoading"
                    variant="primary"
                    @click="emit('confirmed', feedback)"
                >
                    {{ __('common:actions.send') }}
                </MButton>
                <template #content>
                    <div class="tw-max-w-80">
                        {{ __('reporting:feedback_help_text') }}
                    </div>
                </template>
            </MTooltip>
        </template>
    </MModal>
</template>
