<template>
    <CModal
        id="editPlanningModal"
        ref="modalRef"
        :header-title="eventCopy && eventCopy.exist ? __('planning:edit_planning') : __('hr_calendar:new_planning')"
        size="3xl"
        :visible="true"
        @hidden="onHidden"
        @shown="onShown"
    >
        <MForm v-if="eventCopy && !loadingModal">
            <CFormTwoColumns>
                <MFieldset>
                    <MLabel>{{ __('planning:planning_type') }}</MLabel>
                    <SelectEventType
                        v-model="eventType"
                        button-class="tw-w-full tw-text-left"
                        :disabled="!canSelectType"
                        dropdown-class="tw-w-full"
                        :inject-query="selectTypeQuery"
                    >
                        <template #unselectedValue>
                            {{ __('hr_calendar:select_event_type') }}
                        </template>
                    </SelectEventType>
                </MFieldset>
            </CFormTwoColumns>
            <CFormErrorMessageList :errors="eventErrors.get('/attributes/type_id')" />

            <CFormTwoColumns v-if="showForecastOption">
                <MFieldset>
                    <CCheckbox v-model="eventCopy.attributes.forecast">
                        {{ __('common:forecast') }}
                    </CCheckbox>
                </MFieldset>
            </CFormTwoColumns>

            <template v-if="allowMultipleDays">
                <CFormTwoColumns>
                    <template v-if="isFullDay && eventCopy.attributes.date_event">
                        <MFieldset>
                            <MLabel>{{ __('common:date_from') }}</MLabel>
                            <MDatePicker
                                v-model="eventCopy.attributes.date_event.started_at"
                                format="yyyy-MM-dd"
                            />
                        </MFieldset>

                        <MFieldset>
                            <MLabel>{{ __('common:date_to') }}</MLabel>
                            <MDatePicker
                                v-model="eventCopy.attributes.date_event.ended_at"
                                format="yyyy-MM-dd"
                            />
                        </MFieldset>
                    </template>
                    <template v-else-if="eventCopy.attributes.datetime_event">
                        <MFieldset>
                            <MLabel>{{ __('common:date_from') }}</MLabel>
                            <MDatePicker
                                v-model="eventCopy.attributes.datetime_event.started_at"
                                with-time
                            />
                        </MFieldset>

                        <MFieldset>
                            <MLabel>{{ __('common:date_to') }}</MLabel>
                            <MDatePicker
                                v-model="eventCopy.attributes.datetime_event.ended_at"
                                with-time
                            />
                        </MFieldset>
                    </template>

                    <MFieldset class="tw--mt-2">
                        <CCheckbox v-model="isFullDay">
                            {{ isSameDay ? __('hr_calendar:full_day_one') : __('hr_calendar:full_day_other') }}
                        </CCheckbox>
                    </MFieldset>
                </CFormTwoColumns>
                <CFormErrorMessageList :errors="dateError" />
            </template>
            <template v-else>
                <CFormTwoColumns>
                    <MFieldset>
                        <MLabel>{{ __('common:time_from') }}</MLabel>
                        <MTimePicker
                            v-model="startTime"
                            :has-error="eventErrors.has('start_time')"
                            :modifier="startOfMinute"
                        />
                    </MFieldset>
                    <MFieldset>
                        <template v-if="displayDuration">
                            <div class="tw-mb-1 tw-flex tw-items-baseline">
                                <MLabel>
                                    {{ __('common:during') }}
                                </MLabel>
                                <MTooltip class="tw-ml-auto">
                                    <MButton
                                        class="!tw-p-0"
                                        size="sm"
                                        variant="link"
                                        @click="displayDuration = false"
                                    >
                                        <template #left-icons>
                                            <FontAwesomeIcon icon="fa-duotone fa-clock" />
                                        </template>
                                        {{ __('common:end') }}
                                    </MButton>
                                    <template #content>
                                        {{ __('hr_calendar:select_end_hour') }}
                                    </template>
                                </MTooltip>
                            </div>
                            <CInputGroup class="tw-w-full">
                                <CInput
                                    v-model="duration"
                                    min="0"
                                    :placeholder="__('hr_calendar:duration_in_hour')"
                                    type="number"
                                />
                                <CInputAddon>
                                    {{ __('common:hour', {count: duration}) }}
                                </CInputAddon>
                            </CInputGroup>
                        </template>
                        <template v-else>
                            <MFieldset>
                                <div class="tw-mb-1 tw-flex tw-items-baseline">
                                    <MLabel>
                                        {{ __('common:time_to') }}
                                    </MLabel>
                                    <MTooltip class="tw-ml-auto">
                                        <MButton
                                            class="!tw-p-0"
                                            size="sm"
                                            variant="link"
                                            @click="displayDuration = true"
                                        >
                                            <template #left-icons>
                                                <FontAwesomeIcon icon="fa-duotone fa-stopwatch" />
                                            </template>
                                            {{ __('hr_calendar:duration_in_hour') }}
                                        </MButton>
                                        <template #content>
                                            {{ __('hr_calendar:select_duration_for_2424') }}
                                        </template>
                                    </MTooltip>
                                </div>
                                <MTimePicker
                                    v-model="endTime"
                                    :has-error="eventErrors.has('timelapse')"
                                    :modifier="startOfMinute"
                                />
                            </MFieldset>
                        </template>
                    </MFieldset>
                </CFormTwoColumns>
                <CText
                    v-if="!isSameDay"
                    font-size="sm"
                    variant="disabled"
                >
                    <FontAwesomeIcon
                        class="tw-mr-1"
                        icon="fa-duotone fa-exclamation-triangle"
                    />
                    {{ __('hr_calendar:planning_on_multiple_days') }}
                </CText>
                <CFormErrorMessageList :errors="dateError" />
            </template>

            <CFormTwoColumns v-if="filteredOrganizations.length > 1 || groups">
                <MFieldset v-if="filteredOrganizations.length > 1">
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-duotone fa-home" />
                        </template>
                        {{ __('common:organization_one') }}
                    </MLabel>
                    <OrganizationFinder
                        v-model="selectedOrganization"
                        button-class="tw-w-full"
                        :has-unselect="true"
                        :inject-query="organizationFinderQueryBuilder"
                        @update:model-value="eventCopy.kidsGroup().dissociate()"
                    />
                </MFieldset>

                <MFieldset v-if="groups && eventCopy.attributes.organization_id">
                    <MLabel>
                        <template #icon>
                            <FontAwesomeIcon icon="fa-duotone fa-users" />
                        </template>
                        {{ __('common:group_one') }}
                    </MLabel>
                    <SelectGroup
                        button-class="tw-w-full tw-text-left"
                        dropdown-class="tw-w-full"
                        :organization-id="eventCopy.attributes.organization_id"
                        :model-value="eventCopy.kidsGroup().value()"
                        @change="eventCopy.kidsGroup().associate($event)"
                    />
                </MFieldset>
            </CFormTwoColumns>

            <CDisclosure
                :open="false"
                size="sm"
                :title="__('hr_calendar:additional_options')"
            >
                <MFieldset>
                    <MLabel>{{ __('common:note_one') }}</MLabel>
                    <MTextarea v-model="eventCopy.attributes.note" />
                </MFieldset>

                <MFieldset>
                    <CCheckbox v-model="superviseKid">
                        {{ __('hr_calendar:impact_supervision') }}
                    </CCheckbox>
                </MFieldset>
            </CDisclosure>

            <CDisclosure
                v-if="!isTemplate && $can('read', 'staffs_balances')"
                :loading="balanceTypeListLoading"
                :open="true"
                size="sm"
                :title="__('common:balances')"
            >
                <BalanceTypeList
                    v-if="staff && eventType"
                    :event-types="selectedEventTypes"
                    :source="eventCopy"
                    :staff="staff"
                    @changed="allocations = $event"
                    @loading="balanceTypeListLoading = $event"
                />
            </CDisclosure>
        </MForm>
        <loader
            v-else
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />

        <template #footer>
            <template v-if="eventCopy && can('delete', 'staffs_planning')">
                <MButton
                    v-if="!eventCopy.exist || softUpdate"
                    :label="__('common:actions.delete')"
                    variant="danger"
                    @click="$emit('delete', eventCopy)"
                />
                <template v-else>
                    <CTooltip
                        v-if="hasLockedAllocations"
                        placement="bottom"
                    >
                        <MButton
                            :disabled="true"
                            :label="__('common:actions.delete')"
                            variant="danger"
                        />
                        <template #content>
                            {{ __('hr_calendar:explanation_of_event_deletion') }}
                        </template>
                    </CTooltip>
                    <MButton
                        v-else
                        :label="__('common:actions.delete')"
                        variant="danger"
                        @click="confirmRemoveModal"
                    />
                </template>
            </template>
            <MButton
                class="tw-ml-auto"
                :label="__('common:actions.cancel')"
                @click="hideModal()"
            />
            <MButton
                class="tw-ml-2"
                :label="__('common:actions.save')"
                :loading="loading"
                variant="primary"
                @click="save()"
            />
        </template>
    </CModal>
</template>

<script lang="ts">
    import {catcher, Epoch, type Model, ValidationError} from '@meekohq/lumos';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import _orderBy from 'lodash-es/orderBy';
    import moment from 'moment';
    import type {PropType, Ref} from 'vue';
    import {computed, defineComponent, ref, watch} from 'vue';

    import {EventBus} from '@/eventBus';
    import useAbility from '@/modules/app/composables/useAbility';
    import useAuth from '@/modules/app/composables/useAuth';
    import useError from '@/modules/app/composables/useError';
    import useMagicModal from '@/modules/app/composables/useMagicModal';
    import useManager from '@/modules/app/composables/useManager';
    import useMetrics from '@/modules/app/composables/useRum';
    import __ from '@/modules/app/utils/i18n-facade';
    import BalanceTypeList from '@/modules/human-resources/components/balance/BalanceTypeList.vue';
    import SelectEventType from '@/modules/human-resources/components/event-type/SelectEventType.vue';
    import useAllocations from '@/modules/human-resources/composables/allocation/useAllocations';
    import EventModel from '@/modules/human-resources/models/EventModel';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import type TemplateEventModel from '@/modules/human-resources/models/TemplateEventModel';
    import SelectGroup from '@/modules/legacy/components/Modules/SelectGroup.vue';
    import Mirror from '@/modules/legacy/helpers/mirror.helper';
    import {getClosing, getOpening} from '@/modules/legacy/libs/planning/planning';
    import ManagerStore from '@/modules/legacy/store/manager.store';
    import {useDateTimeModifiersPresets} from '@/modules/meeko-ui/composables/useDateTimeModifiers';
    import {useLegacyModalBus} from '@/modules/meeko-ui/composables/useLegacyModalBus';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import KidsGroupModel from '@/modules/organization/models/KidsGroupModel';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';

    export default defineComponent({
        components: {
            BalanceTypeList,
            SelectEventType,
            SelectGroup,
            OrganizationFinder,
        },
        props: {
            event: {
                type: Object as PropType<EventModel | TemplateEventModel>,
                required: true,
            },
            isTemplate: {
                type: Boolean,
                default: false,
            },
            softUpdate: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['delete', 'add', 'update:event', 'hidden'],
        setup(props, {emit}) {
            const {legacyUser: user} = useAuth();
            const {can} = useAbility();
            const {addAction} = useMetrics();
            const {startOfMinute} = useDateTimeModifiersPresets();

            const eventCopy = ref(props.event.clone()) as Ref<EventModel | TemplateEventModel>;
            const eventType = ref<EventTypeModel>();
            const groups = ref<number>();
            const startTime = ref(Epoch.now().startOfDay());
            const endTime = ref(Epoch.now().endOfDay());
            const duration = ref(0);
            const displayDuration = ref(false);
            const eventErrors = useError({
                timelapse: {
                    '0x2EB54B8BFA': () => __('common:errors.0x2EDA212753'),
                },
            });
            const loading = ref(false);
            const loadingModal = ref(false);
            let rememberEventCopy = new Mirror();

            const isFullDay = ref();

            const hasLockedAllocations = computed(() => {
                const event = eventCopy.value;
                if (event && event instanceof EventModel) {
                    return event
                        .balanceAllocations()
                        .value()
                        .contains(allocation => allocation.attributes.confirmed_at !== null);
                }

                return false;
            });

            const organization = computed(() => {
                return ManagerStore.legacyNursery;
            });
            const {activeOrganization} = useManager();
            const selectedOrganization = ref<OrganizationModel>();
            const organizationFinderQueryBuilder = computed(() => OrganizationModel.query().scope('userOrganizations'));

            const canSelectType = computed(() => {
                if (eventCopy.value instanceof EventModel) {
                    return !eventCopy.value.attributes.request_id;
                }

                return true;
            });

            // Modal
            const {modal: modalRef, hide: hideModal} = useLegacyModalBus();

            // Associate EventType when it changes
            watch(eventType, value => {
                eventCopy.value?.eventType().associate(value);
            });

            // Associate Organization when it changes
            watch(selectedOrganization, value => {
                eventCopy.value?.organization().associate(value);
            });

            // Get staff model
            const staff = ref();
            watch(
                () => eventCopy.value,
                async value => {
                    if (value instanceof EventModel) {
                        staff.value = await value.staff().load();
                    }
                },
                {immediate: true}
            );

            // Allocations
            const {allocations, save: saveAllocations} = useAllocations();

            watch(isFullDay, val => {
                // Remember dates when switching from datetime to date
                if (eventCopy.value) {
                    // Variable for readability
                    const event = eventCopy.value;

                    if (val) {
                        // @ts-ignore
                        const initialStartedAt = event.attributes.datetime_event
                            ? event.attributes.datetime_event.started_at
                            : rememberEventCopy.value.attributes.date_event.started_at;
                        // @ts-ignore
                        const initialEndedAt = event.attributes.datetime_event
                            ? event.attributes.datetime_event.ended_at
                            : rememberEventCopy.value.attributes.date_event.ended_at;

                        // Set date from remember one
                        // @ts-ignore
                        event.attributes.date_event = {
                            started_at: moment(initialStartedAt).format('YYYY-MM-DD'),
                            ended_at: moment(initialEndedAt).format('YYYY-MM-DD'),
                        };

                        // Remember datetime before reset
                        const datetimeToRemember =
                            event.attributes.datetime_event ?? rememberEventCopy.value.attributes.datetime_event;
                        rememberEventCopy = new Mirror(event);
                        rememberEventCopy.value.attributes.datetime_event = datetimeToRemember;
                    } else {
                        const initialStartedAt = rememberEventCopy.value.attributes.datetime_event.started_at;
                        const initialEndedAt = rememberEventCopy.value.attributes.datetime_event.ended_at;
                        const rememberedStartDay = moment(
                            rememberEventCopy.value.attributes.date_event.started_at
                        ).dayOfYear();
                        const rememberedEndDay = moment(
                            rememberEventCopy.value.attributes.date_event.ended_at
                        ).dayOfYear();

                        // Set date from remember one
                        // @ts-ignore
                        event.attributes.datetime_event = {
                            started_at: moment(initialStartedAt).dayOfYear(rememberedStartDay).toISOString(),
                            ended_at: moment(initialEndedAt).dayOfYear(rememberedEndDay).toISOString(),
                        };
                        // Remember date before reset
                        rememberEventCopy = new Mirror(event);
                    }
                }
            });

            const filteredOrganizations = computed(() => {
                const nurseries = _cloneDeep(user.value.nurseries);
                nurseries.forEach(nursery => {
                    nursery.id = nursery.id.toString();
                });

                return _orderBy(nurseries, 'name');
            });

            const computedStartedAt = computed(() => {
                const actual = moment().format('YYYY-MM-DD');

                return moment(`${actual} ${startTime.value.toFormat('HH:mm')}`, 'YYYY-MM-DD HH:mm');
            });

            const isSameDay = computed(() => {
                if (props.isTemplate) {
                    if (displayDuration.value) {
                        const computedEndedAt = computedStartedAt.value.clone().add(duration.value, 'hours');

                        return moment(computedStartedAt.value).isSame(computedEndedAt, 'day');
                    }

                    return true;
                }

                return eventCopy.value?.isSameDay || false;
            });

            const allowMultipleDays = computed(() => !props.isTemplate);

            const superviseKid = ref(true);

            const showForecastOption = computed(() => {
                return !eventCopy.value?.extra.forceReal;
            });

            watch(eventType, value => {
                if (value && eventCopy.value) {
                    // @ts-ignore
                    eventCopy.value.attributes.factor = eventCopy.value.eventType().value().factor;
                    if (eventCopy.value.attributes.supervise_kid !== null) {
                        superviseKid.value = eventCopy.value.attributes.supervise_kid as boolean;
                    } else {
                        const defaultSupervision = eventCopy.value.attributes.forecast
                            ? value.attributes.enable_supervision_for_forecast
                            : value.attributes.enable_supervision_for_actual;

                        superviseKid.value = defaultSupervision as boolean;
                    }
                }
            });

            // Some eventTypes can be configured to have a supervision rule depending on its forecast's option
            watch(
                () => eventCopy.value?.attributes.forecast,
                value => {
                    // When forecast changes, recheck which supervision rule to set
                    const defaultSupervision = value
                        ? eventType.value?.attributes.enable_supervision_for_forecast
                        : eventType.value?.attributes.enable_supervision_for_actual;
                    superviseKid.value = defaultSupervision as boolean;
                }
            );

            const selectTypeQuery = computed(() => {
                if (props.isTemplate) {
                    return EventTypeModel.query().scope('withoutAbsences');
                }

                return null;
            });

            async function onShown() {
                // Variable for readability
                loadingModal.value = true;
                const event = eventCopy.value as EventModel | TemplateEventModel;
                groups.value = await KidsGroupModel.query().where('nursery_id', organization.value.id).count();
                if (props.isTemplate) {
                    startTime.value = Epoch.fromMoment(event.startedAt);
                    endTime.value = Epoch.fromMoment(event.endedAt);
                    // @ts-ignore
                    duration.value = event.attributes.timelapse ? event.attributes.timelapse / 3600 : 0;

                    displayDuration.value = !event.isSameDay;
                } else {
                    // Init new copy to remember when swaping between date and datetime
                    rememberEventCopy = new Mirror(event);
                    if (rememberEventCopy.value.attributes.datetime_event === null) {
                        const currentDate = rememberEventCopy.value.startedAt.format('MM/DD/YYYY');
                        const startTime = getOpening(organization.value.openingHours, false, true);
                        const endTime = getClosing(organization.value.openingHours, false, true);
                        rememberEventCopy.value.attributes.datetime_event = {
                            started_at: moment(`${currentDate} ${startTime}`, 'MM/DD/YYYY HH:mm').format(),
                            ended_at: moment(`${currentDate} ${endTime}`, 'MM/DD/YYYY HH:mm').format(),
                        };
                    }

                    if (
                        rememberEventCopy.value.attributes.date_event === null ||
                        rememberEventCopy.value.attributes.date_event.started_at === null
                    ) {
                        rememberEventCopy.value.attributes.date_event = {
                            started_at: rememberEventCopy.value.attributes.datetime_event.started_at,
                            ended_at: rememberEventCopy.value.attributes.datetime_event.ended_at,
                        };
                        isFullDay.value = false;
                    } else {
                        isFullDay.value = true;
                    }
                }

                if (event.attributes.type_id) {
                    // Get eventType from event
                    eventType.value = await event.eventType().load();
                } else {
                    // Get and assign default type
                    eventType.value = await EventTypeModel.query().scope('defaultType').firstOrFail();
                }

                //if event has related organization load it
                //if not, auto select active organization only in creation to avoid auto select for existing events saved without organization
                if (event.attributes.organization_id) {
                    selectedOrganization.value = await event.organization().fresh();
                } else {
                    if (!event.exist) {
                        selectedOrganization.value = activeOrganization.value;
                    }
                }

                loadingModal.value = false;
            }

            async function save() {
                if (eventCopy.value) {
                    loading.value = true;
                    eventErrors.reset();
                    if (props.isTemplate) {
                        // @ts-ignore
                        // Returns the number of seconds between startTime and the start of the day
                        eventCopy.value.attributes.start_time =
                            startTime.value.toTimestamp() - startTime.value.startOfDay().toTimestamp();
                        if (displayDuration.value) {
                            // User has manually set a duration in hours
                            // @ts-ignore
                            eventCopy.value.attributes.timelapse = duration.value * 3600;
                        } else {
                            // User has set endtime, transform into timelapse
                            // Returns the number of seconds between endTime and the start of the day
                            const endInSeconds = endTime.value.toTimestamp() - endTime.value.startOfDay().toTimestamp();
                            // @ts-ignore
                            eventCopy.value.attributes.timelapse = endInSeconds - eventCopy.value.attributes.start_time;
                        }
                    } else {
                        if (isFullDay.value) {
                            (eventCopy.value as EventModel).attributes.datetime_event = null;
                        } else {
                            (eventCopy.value as EventModel).attributes.date_event = null;
                        }
                    }

                    const defaultSupervision = eventCopy.value.attributes.forecast
                        ? eventType.value?.attributes.enable_supervision_for_forecast
                        : eventType.value?.attributes.enable_supervision_for_actual;

                    if (defaultSupervision === superviseKid.value) {
                        // Set to null if user has selected same value than the default value
                        eventCopy.value.attributes.supervise_kid = null;
                    } else {
                        eventCopy.value.attributes.supervise_kid = superviseKid.value;
                    }

                    // Get and use referenceEvent to fill note
                    // @ts-ignore
                    if (!eventCopy.value.attributes.note && eventCopy.value.attributes.reference_event_id) {
                        // @ts-ignore
                        const referenceEvent = await eventCopy.value.referenceEvent().fresh();
                        eventCopy.value.attributes.note = referenceEvent.attributes.note;
                    }

                    try {
                        const metricsEventExists = eventCopy.value.exist;

                        if (!props.softUpdate) {
                            await (eventCopy.value as Model).save();
                        }

                        if (allocations.value.length) {
                            addAction('M_HR_Event_Balance_Allocate', {
                                value: metricsEventExists ? 'onEdit' : 'onCreate',
                            });
                        }

                        // Save allocations
                        await saveAllocations(eventCopy.value as any);

                        // Emit to calendar to push in current list
                        if (!props.event.exist) {
                            emit('add', eventCopy.value);
                        }

                        (eventCopy.value as any).copyTo(props.event as any);
                        // emit('update:event', event);

                        if (!props.isTemplate) {
                            EventBus.$emit('calendar:staffs:refresh');
                        }

                        loading.value = false;
                        hideModal();
                    } catch (e) {
                        loading.value = false;
                        catcher()
                            .on(ValidationError, value => {
                                eventErrors.addValidationError(value);
                            })
                            .catch(e as Error);
                    }
                }
            }

            const dateError = computed(() => {
                const dateErrors = eventErrors.get('/attributes/date_event/started_at');
                const datetimeErrors = eventErrors.get('/attributes/datetime_event/started_at');
                const templateErrors = eventErrors.get('/attributes/start_time');
                const timelapseErrors = eventErrors.get('timelapse');

                return dateErrors.concat(datetimeErrors).concat(templateErrors).concat(timelapseErrors).unique();
            });

            async function remove() {
                await eventCopy.value?.delete();
                eventCopy.value?.copyTo(props.event as any);

                // Emit to calendar to remove from current list
                emit('delete', eventCopy.value);
                emit('update:event', null);
                EventBus.$emit('calendar:staffs:refresh');
                hideModal();
            }

            function confirmRemoveModal() {
                useMagicModal().deleteConfirmationModal({
                    title: __('hr_calendar:delete_planning'),
                    text: __('hr_calendar:delete_planning_ask'),
                    onConfirm: async () => {
                        await remove();
                    },
                });
            }

            const selectedEventTypes = computed(() => {
                return [eventType.value];
            });

            const balanceTypeListLoading = ref(false);

            const onHidden = function () {
                eventErrors.reset();
                emit('hidden');
            };

            return {
                can,
                loadingModal,
                groups,
                selectedOrganization,
                organizationFinderQueryBuilder,
                eventCopy,
                eventType,
                staff,
                startTime,
                endTime,
                duration,
                displayDuration,
                isFullDay,
                filteredOrganizations,
                superviseKid,
                selectTypeQuery,
                showForecastOption,
                isSameDay,
                allowMultipleDays,
                hasLockedAllocations,
                loading,
                dateError,

                modal: modalRef,
                modalRef,
                hideModal,

                onShown,
                onHidden,
                save,
                remove,
                confirmRemoveModal,
                allocations,
                selectedEventTypes,

                balanceTypeListLoading,
                eventErrors,
                canSelectType,

                startOfMinute,
            };
        },
    });
</script>
