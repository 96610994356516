<template>
    <MBox class="tw-p-4">
        <div>
            <div
                class="tw-flex tw-cursor-pointer tw-flex-wrap tw-items-center tw-justify-between tw-gap-2"
                @click="showCard = !showCard"
            >
                <div>
                    <MHeading>
                        <FontAwesomeIcon
                            class="tw-mr-2 tw-text-primary-500"
                            icon="fa-solid fa-chart-bar"
                        />
                        {{ __('common:pricing_calculation_method') }}
                    </MHeading>
                    <div class="tw-mt-1 tw-text-sm tw-text-gray-500">
                        {{ __('billing_setting:different_rate_table') }}
                    </div>
                </div>
                <MButton
                    icon-menu
                    @click.stop="showCard = !showCard"
                >
                    <template v-if="showCard">
                        {{ __('common:actions.close') }}
                    </template>
                    <template v-else>
                        {{ __('common:actions.open') }}
                    </template>
                </MButton>
            </div>
            <div
                v-if="showCard"
                class="tw-mb-4"
            >
                <div class="Normal__row">
                    <div>
                        <div>{{ __('billing_setting:decreasing_rate_according') }}</div>
                        <MSmall>
                            {{ __('billing_setting:ask_decreasing_rate_according') }}
                        </MSmall>
                    </div>
                    <div>
                        <div class="tw-flex tw-flex-wrap tw-gap-2">
                            <MButtonSelect
                                v-model="config.pricing.discount.on_salary"
                                :options="booleanOptions"
                            />
                            <MButton
                                v-if="config.pricing.discount.on_salary"
                                :label="__('billing_setting:income_bracket_dots')"
                                variant="primary"
                                @click="incomeTableModal.show"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="config.billing.cycle !== 'month'"
                    class="Normal__row"
                >
                    <div>
                        <div>{{ __('billing_setting:decreasing_rate_based_child_presence') }}</div>
                        <MSmall>
                            {{ __('billing_setting:ask_decreasing_rate_based_child_presence') }}
                        </MSmall>
                    </div>
                    <!--N'afficher que la dégrésivité supérieure par rapport à la facturation (si facturation à la demi journée alors pas de dégrésivité horaire..)-->
                    <div>
                        <MSelectMenu
                            v-model="config.pricing.discount.on_presence"
                            :options="decreasingPresenceOptions"
                            text-path="text"
                            value-path="value"
                        />
                    </div>
                </div>

                <div
                    v-if="config.billing.cycle === 'month'"
                    class="tw-mt-6"
                >
                    <div>{{ __('billing_setting:monthly_rate') }}</div>
                    <MSmall>{{ __('billing_setting:ask_monthly_rate') }}</MSmall>
                </div>
                <div class="tw-mt-2">
                    <!-- Aucune dégressivité -->
                    <template v-if="config.pricing.discount.on_presence === 'none'">
                        <price-none
                            :brackets="config.pricing.discount.on_salary ? config.pricing.discount.brackets.length : 1"
                            :nursery="nursery"
                            :prices="config.pricing.discount.prices"
                            @update-price="priceUpdated"
                        />
                    </template>

                    <!-- dégressivité par jour -->
                    <template v-else-if="config.pricing.discount.on_presence === 'day'">
                        <price-day
                            :brackets="config.pricing.discount.on_salary ? config.pricing.discount.brackets.length : 1"
                            :nursery="nursery"
                            :prices="config.pricing.discount.prices"
                            @update-price="priceUpdated"
                        />
                    </template>

                    <!-- dégressivité par demi-journée -->
                    <template v-else-if="config.pricing.discount.on_presence === 'halfday'">
                        <price-halfday
                            :brackets="config.pricing.discount.on_salary ? config.pricing.discount.brackets.length : 1"
                            :nursery="nursery"
                            :prices="config.pricing.discount.prices"
                            @update-price="priceUpdated"
                        />
                    </template>

                    <!-- dégressivité par heure -->
                    <template v-else-if="config.pricing.discount.on_presence === 'hour'">
                        <price-hour
                            :brackets="config.pricing.discount.on_salary ? config.pricing.discount.brackets.length : 1"
                            :nursery="nursery"
                            :prices="config.pricing.discount.prices"
                            @update-price="priceUpdated"
                        />
                    </template>
                </div>

                <!-- PRECISION / ARRONDIS -->
                <div>
                    <MButton
                        :label="__('billing_setting:rounding_configure')"
                        size="sm"
                        variant="link"
                        @click="showPrecisions = !showPrecisions"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-gear" />
                        </template>
                    </MButton>
                    <div
                        v-if="showPrecisions"
                        class="tw-mt-2 tw-px-2"
                    >
                        <precisions
                            :only-price="false"
                            :config-key="config.billing"
                        />
                    </div>
                </div>

                <!-- CMG -->
                <template v-if="config.funding !== 'none'">
                    <hr class="hr" />
                    <div class="tw-mt-6">
                        <div>
                            <div class="tw-flex tw-flex-wrap tw-items-baseline tw-gap-2">
                                {{ __('billing_setting:amount_cmg') }}
                                <MButton
                                    :label="showCMG ? __('common:actions.hide') : __('common:actions.show')"
                                    variant="link"
                                    @click="showCMG = !showCMG"
                                />
                            </div>
                            <MSmall>{{ __('billing_setting:based_amout_cmg') }}</MSmall>
                        </div>
                    </div>
                    <div
                        v-if="showCMG"
                        class="tw-mt-4 tw-flex tw-flex-col tw-items-start tw-gap-2"
                    >
                        <MButton
                            class="tw-text-info-500"
                            variant="link"
                            @click="updateFromCAF"
                        >
                            <template #left-icons>
                                <FontAwesomeIcon icon="fa-solid fa-sync" />
                            </template>
                            {{ __('billing_setting:update_with_latest_data') }}
                        </MButton>
                        <MButton
                            class="tw-text-info-500"
                            icon-plus
                            :label="__('billing_setting:use_majored_percent_amount')"
                            variant="link"
                            @click="increaseCMG"
                        />
                        <cmg
                            :brackets="config.pricing.discount.brackets.length"
                            :config="config"
                            :nursery="nursery"
                        />
                    </div>
                    <CHStack
                        align="center"
                        class="tw-mt-4"
                        gap="2"
                    >
                        {{ __('billing_setting:annual_tax_credit') }}
                        <MButton
                            :label="showCI ? __('common:actions.hide') : __('common:actions.show')"
                            variant="link"
                            @click="showCI = !showCI"
                        />
                    </CHStack>
                    <CStack
                        v-if="showCI"
                        :direction="direction"
                        :distribute="distribute"
                        :gap="4"
                    >
                        <div class="tw-w-1/2">
                            <CText
                                font-size="sm"
                                variant="gray"
                            >
                                {{ __('billing_setting:deductible_tax_credit_amount') }}
                            </CText>
                            <CTooltip placement="bottom-start">
                                <FontAwesomeIcon
                                    class="tw-ml-1 tw-text-cyan-500"
                                    icon="info-circle"
                                />
                                <template #content>
                                    <CText>
                                        {{ __('billing_setting:tax_credit_explanation') }}
                                    </CText>
                                </template>
                            </CTooltip>
                        </div>
                        <div class="tw-w-1/2">
                            <CInputGroup class="tw-w-2/3">
                                <CInput
                                    v-model.number="config.pricing.discount.tax_credit"
                                    placeholder="3500"
                                    type="text"
                                />
                                <CInputAddon>
                                    {{ nursery.currency }}
                                </CInputAddon>
                            </CInputGroup>
                        </div>
                    </CStack>
                </template>
            </div>
        </div>

        <MModal
            v-if="incomeTableModal.isVisible.value"
            :header-title="__('common:income_bracket_table')"
            :modal="incomeTableModal"
            size="5xl"
        >
            <brackets v-model:prices="config.pricing.discount.brackets" />
            <template #footer-end="{hide}">
                <MButton
                    :label="__('common:actions.close')"
                    @click="hide"
                />
            </template>
        </MModal>
    </MBox>
</template>

<script>
    import _cloneDeep from 'lodash-es/cloneDeep';

    import useModal from '@/modules/app/composables/useModal';
    import {useResponsiveValue} from '@/modules/app/composables/useResponsiveValue';
    import __ from '@/modules/app/utils/i18n-facade';
    import defaultConfig from '@/modules/legacy/contents/defaultConfig';

    import Cmg from './Array/Cmg.vue';
    import Day from './Array/Day.vue';
    import HalfDay from './Array/HalfDay.vue';
    import Hour from './Array/Hour.vue';
    import None from './Array/None.vue';
    import Precisions from './Array/Precisions.vue';
    import Brackets from './Brackets.vue';

    export default {
        components: {
            'brackets': Brackets,
            'price-none': None,
            'price-hour': Hour,
            'price-day': Day,
            'price-halfday': HalfDay,
            'cmg': Cmg,
            Precisions,
        },
        props: {
            config: {},
            nursery: {},
        },
        data() {
            return {
                forceshow: true,
                showCard: false,
                showCMG: false,
                showCI: false,
                showPrecisions: false,
                booleanOptions: [
                    {
                        text: __('common:yes'),
                        value: true,
                    },
                    {
                        text: __('common:no'),
                        value: false,
                    },
                ],
                decreasingPresenceOptions: [
                    {
                        text: __('billing_setting:decreased_none'),
                        value: 'none',
                    },
                    {
                        text: __('billing_setting:decreased_on_hours'),
                        value: 'hour',
                    },
                    {
                        text: __('billing_setting:decreased_on_half_days'),
                        value: 'halfday',
                    },
                    {
                        text: __('billing_setting:decreased_on_days'),
                        value: 'day',
                    },
                ],
                kid: 2,
                income: 23400,
            };
        },
        computed: {
            direction() {
                return useResponsiveValue({base: 'col', md: 'row'}).value;
            },
            distribute() {
                return useResponsiveValue({md: 'between'}).value;
            },
            incomeTableModal() {
                return useModal();
            },
        },
        methods: {
            priceUpdated(prices) {
                this.config.pricing.discount.prices = prices;
            },
            updateFromCAF() {
                const myConfig = _cloneDeep(defaultConfig);
                this.config.cmg.brackets = myConfig.cmg.brackets;
            },
            increaseCMG() {
                const myConfig = _cloneDeep(defaultConfig);
                this.config.cmg.brackets = myConfig.cmg.brackets_increased;
            },
        },
    };
</script>

<style scoped>
    .Normal__row {
        @apply tw-mt-6 tw-grid tw-grid-cols-1 tw-items-center tw-gap-2 sm:tw-grid-cols-2;
    }
</style>
