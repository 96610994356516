import {Epoch} from '@meekohq/lumos';
import type {Ref} from 'vue';
import {computed} from 'vue';

import __ from '@/modules/app/utils/i18n-facade';
import useMCalendarInputState from '@/modules/meeko-ui/components/MCalendarInput/useMCalendarInputState';
import useLocalDateTimeFormats from '@/modules/meeko-ui/composables/useLocalDateTimeFormats';
import type {DateTimeSingle} from '@/modules/meeko-ui/types/DateTime';

export default function useMCalendarInputFieldState(
    value: Ref<DateTimeSingle>,
    state: ReturnType<typeof useMCalendarInputState>,
    tabIndex: number,
    disabled: Ref<boolean>,
    emit: {
        (e: 'focus', value: boolean): void;
        (e: 'update:modelValue', value: DateTimeSingle): void;
    }
) {
    const {mode} = state;

    const showDate = ['dateTime', 'date', 'month', 'year'].includes(mode);
    const showTime = ['dateTime', 'time'].includes(mode);

    const datePlaceholder = computed(() => {
        if (!showDate) {
            return '';
        }

        return __('calendar:input_placeholder_date', {context: mode});
    });

    const timePlaceholder = computed(() => {
        if (!showTime) {
            return '';
        }

        return __('calendar:input_placeholder_time');
    });

    const placeholder = computed(() => {
        if (showDate && showTime) {
            return `${datePlaceholder.value} ${timePlaceholder.value}`;
        }

        if (showDate) {
            return datePlaceholder.value;
        }

        if (showTime) {
            return timePlaceholder.value;
        }

        return '';
    });

    const formattedDate = computed(() => {
        if (!value.value || !showDate) {
            return undefined;
        }

        return value.value.toFormat(getDateFormat());
    });

    const formattedTime = computed(() => {
        if (!value.value || !showTime) {
            return undefined;
        }

        return value.value.toFormat(getTimeFormat());
    });

    const textValue = computed(() => {
        if (showDate && showTime) {
            if (!formattedDate.value && !formattedTime.value) {
                return undefined;
            }

            return `${formattedDate.value} ${formattedTime.value}`;
        }

        if (showDate) {
            return formattedDate.value;
        }

        if (showTime) {
            return formattedTime.value;
        }

        return undefined;
    });

    function handleInput(newValue: string) {
        if (!newValue) {
            emit('update:modelValue', undefined);

            return;
        }

        const dateFormat = getDateFormat();
        const timeFormat = getTimeFormat();

        if (showDate && showTime) {
            handleDateTimeInput(newValue, dateFormat, timeFormat);
        }

        if (dateFormat) {
            handleDateInput(newValue, dateFormat);
        }

        if (showTime) {
            handleTimeInput(newValue, timeFormat);
        }
    }

    function handleDateTimeInput(newValue: string, dateFormat: string, timeFormat: string) {
        if (newValue.length !== dateFormat.length + 1 + timeFormat.length) {
            return;
        }

        const dateSlice = newValue.slice(0, dateFormat.length);
        const timeSlice = newValue.slice(dateFormat.length + 1);

        const date = parseInputSlice(dateSlice, dateFormat);
        const time = parseInputSlice(timeSlice, timeFormat);

        if (date?.isValid && time?.isValid) {
            emit(
                'update:modelValue',
                date.set({
                    hour: time.hour,
                    minute: time.minute,
                    second: time.second,
                })
            );
        }
    }

    function handleDateInput(newValue: string, dateFormat: string) {
        if (newValue.length !== dateFormat.length) {
            return;
        }

        const date = parseInputSlice(newValue, dateFormat);
        if (date?.isValid) {
            emit('update:modelValue', date);
        }
    }

    function handleTimeInput(newValue: string, timeFormat: string) {
        if (newValue.length !== timeFormat.length) {
            return;
        }

        const time = parseInputSlice(newValue, timeFormat);
        if (time?.isValid) {
            emit('update:modelValue', time);
        }
    }

    function getDateFormat() {
        if (!showDate) {
            return '';
        }

        const localFormats = useLocalDateTimeFormats().getLocalFormats();

        switch (mode) {
            case 'dateTime':
            case 'date':
                return localFormats.date;
            case 'month':
                return localFormats.month;
            case 'year':
                return localFormats.year;
            default:
                return '';
        }
    }

    function getTimeFormat() {
        if (!showTime) {
            return '';
        }

        const localFormats = useLocalDateTimeFormats().getLocalFormats();

        return localFormats.time;
    }

    function parseInputSlice(slice: string, format: string) {
        return Epoch.parse(slice, format) ?? undefined;
    }

    function handleKeydownTab(event: KeyboardEvent) {
        if (tabIndex === 1) {
            event.preventDefault();
        }

        if ((event.target as HTMLInputElement).value) {
            handleFocusLost((event.target as HTMLInputElement).value);
        }

        emit('focus', false);
    }

    function handleVirtualInputClick() {
        if (!disabled.value) {
            emit('focus', true);
        }
    }

    /**
     * Pads a partial time string based on the provided format.
     *
     * @param {string} value - The partial time string to be padded.
     * @param {string} format - The format to pad the time string to. Supported formats are:
     *                          'HH', 'mm', 'HHmm', 'mmss', 'HH:mm', 'mm:ss'.
     * @returns {string} - The padded time string.
     */
    function padPartialTime(value: string, format: string): string {
        if (value.length === 0) {
            return '';
        }

        // Helper function to pad a partial time string to 4 digits (HHmm, mmss, HH:mm, mm:ss formats)
        const padFourDigits = (val: string) => {
            if (val.length === 1) {
                return '0' + val + '00';
            }

            if (val.length === 2) {
                return val + '00';
            }

            if (val.length === 3) {
                return '0' + val.slice(0, 1) + val.slice(1);
            }

            if (val.length === 4) {
                return val.slice(0, 2) + val.slice(2);
            }

            return val;
        };

        value = value.replace(':', '').slice(0, 4);

        // Pad single digit hour or minute with leading zero
        if (format === 'HH' || format === 'mm') {
            return value.padStart(2, '0');
        }

        // Pad value for hhmm, mmss cases
        value = padFourDigits(value);

        // Add potential separator if needed
        return format.includes(':') ? value.slice(0, 2) + ':' + value.slice(2) : value;
    }

    function handleFocusLost(inputValue: string) {
        if (mode === 'time') {
            const timeFormat = getTimeFormat();
            // pad partial time for value
            const paddedValue = padPartialTime(inputValue, timeFormat);
            // update model value
            handleTimeInput(paddedValue, timeFormat);
        }
    }

    return {
        placeholder,
        datePlaceholder,
        timePlaceholder,
        showDate,
        showTime,
        textValue,
        formattedDate,
        formattedTime,
        handleInput,
        handleKeydownTab,
        handleVirtualInputClick,
        handleFocusLost,
        padPartialTime,
    };
}
