import mitt from 'mitt';
import {markRaw, ref} from 'vue';

import useModalManager from '@/modules/app/composables/useModalManager';
import useUniqueComponentId from '@/modules/app/composables/useUniqueComponentId';

export interface ModalEventType {
    show: void;
    shown: void;
    hide: void;
    hidden: void;
    mounted: void;
}

export type ModalType = ReturnType<typeof useModal>;

type ListenersType = Record<string, Function | Function[]>;

export interface ModalOption {
    component?: unknown;
    props?: Record<string, unknown>;
    listeners?: (modal: ModalType) => ListenersType;
}

function useModal(modalOption: ModalOption = {}) {
    const id = useUniqueComponentId();

    const {fire} = useModalManager();

    const isVisible = ref(false);

    const bus = mitt<ModalEventType>();

    if (modalOption.component) {
        markRaw(modalOption.component);
    }

    const modal = ref(modalOption);

    const alreadyOnPortal = ref(false);

    // bus.on('shown', () => isVisible.value = true);
    bus.on('hidden', () => (isVisible.value = false));

    const output = {
        id,
        bus,
        show,
        hide,
        isVisible,
        modal,
        alreadyOnPortal,
    };

    function show() {
        if (modalOption.component) {
            alreadyOnPortal.value = true;
            fire(output, () => {
                isVisible.value = true;
            });
        } else {
            bus.on('mounted', () => {
                bus.emit('show');
            });

            isVisible.value = true;
        }
    }

    function hide() {
        bus.emit('hide');
    }

    return output;
}

export default useModal;
