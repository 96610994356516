<template>
    <SimplePageTemplate>
        <template #title>
            <MPageTitle>
                {{ __('app:menu.staffs_planning') }}
            </MPageTitle>
        </template>
        <template #actions>
            <TopActions
                current-display="Journalier"
                :nursery="organization"
                @manage-templates="showManageTemplatesModal"
                @use-templates="showUseTemplateModal"
            />
        </template>
        <template #content>
            <TopActionsSwitcher
                :current-display="__('common:daily')"
                :nursery="organization"
            />
            <MBox class="tw-mt-3 tw-p-5">
                <div class="tw-mb-2 tw-flex tw-flex-wrap print:tw-hidden">
                    <div class="tw-order-2 tw-w-1/2 tw-text-left sm:tw-order-1 sm:tw-w-1/3">
                        <MButton
                            variant="success"
                            @click="date = date.clone().subtract(1, 'weeks')"
                        >
                            <FontAwesomeIcon
                                class="tw-mr-1"
                                icon="fa-solid fa-angle-left"
                            />
                            {{ __('common:longweek_with_number', {week: fromMoment(date).subWeeks(1).weekNumber}) }}
                        </MButton>
                    </div>
                    <div class="tw-order-1 tw-mb-3 tw-w-full tw-text-center sm:tw-order-2 sm:tw-w-1/3">
                        <MHeading>
                            {{ __('common:longweek_with_number', {week: fromMoment(date).weekNumber}) }}
                        </MHeading>
                        <span class="tw-text-gray-500">
                            {{ fromMoment(date).startOfWeek().toLocaleString(Epoch.presets.DATE_SHORT) }} -
                            {{ fromMoment(date).endOfWeek().toLocaleString(Epoch.presets.DATE_SHORT) }}
                        </span>
                    </div>
                    <div class="tw-order-3 tw-w-1/2 tw-text-right sm:tw-w-1/3">
                        <MButton
                            variant="success"
                            @click="date = date.clone().add(1, 'weeks')"
                        >
                            {{ __('common:longweek_with_number', {week: fromMoment(date).addWeeks(1).weekNumber}) }}
                            <FontAwesomeIcon
                                class="tw-ml-1"
                                icon="fa-solid fa-angle-right"
                            />
                        </MButton>
                    </div>
                </div>
                <h4
                    class="h4 tw-mb-4 tw-mt-0 tw-hidden tw-w-full tw-justify-center tw-capitalize tw-text-blue-500 print:tw-flex"
                >
                    {{
                        fromMoment(date).toLocaleString({
                            weekday: 'long',
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                        }) +
                        ' - ' +
                        __('common:longweek_with_number', {week: fromMoment(date).weekNumber})
                    }}
                </h4>
                <div class="tw-mb-4 tw-flex tw-justify-center print:tw-hidden">
                    <div class="tw-flex tw-overflow-x-auto tw-p-3">
                        <div
                            v-for="(day, i) in enumerateDaysBetweenDates"
                            :key="'day' + i"
                            class="tw-mx-2 tw-w-32 tw-shrink-0 tw-cursor-pointer tw-rounded-lg tw-bg-gray-100 tw-p-1 tw-text-center tw-text-gray-600 tw-transition-all hover:tw-bg-gray-200"
                            :class="[
                                {
                                    'tw-text-blue-500 tw-ring tw-ring-primary-500 tw-ring-offset-2': day.isSame(
                                        moment().startOf('day')
                                    ),
                                },
                                {
                                    'tw-scale-110 !tw-bg-blue-500 !tw-text-white hover:!tw-bg-blue-500': date.isBetween(
                                        day.clone().startOf('day'),
                                        day.clone().endOf('day'),
                                        null,
                                        '[]'
                                    ),
                                },
                            ]"
                            @click="date = day"
                        >
                            <h1 class="h1 tw-mb-0 tw-text-inherit">
                                {{ fromMoment(day).day }}
                            </h1>
                            <h5 class="h5 tw-uppercase tw-text-inherit">
                                {{ fromMoment(day).weekdayShort.replace('.', '') }}
                            </h5>
                            <OccupationBadge
                                class="tw-mb-1 tw-mt-2"
                                :day="day"
                                enable-half-day
                                :loading="occupationLoading"
                                :occupations="occupations"
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <div
                        class="tw-mb-2 tw-hidden tw-w-full sm:tw-block print:tw-block"
                        :style="
                            mainPayload.fullDayEvents.count()
                                ? 'padding-left: 205px; padding-right: 55px'
                                : 'padding-left: 185px; padding-right: 55px'
                        "
                    >
                        <Hours
                            :closing="mainPayload.closing"
                            :opening="mainPayload.opening"
                        />
                    </div>
                    <div class="tw-mb-2 tw-w-full">
                        <div class="tw-flex tw-w-full tw-flex-wrap sm:tw-flex-nowrap print:tw-flex-nowrap">
                            <FilterGroup
                                class="tw-mb-2 tw-w-full tw-max-w-44 sm:tw-mb-0"
                                :nursery="organization"
                                :model-value="selectedGroup"
                                variant="primary"
                                @update:model-value="selectedGroup = $event"
                            />
                            <summary-bar
                                class="tw-w-full"
                                :closing="mainPayload.closing"
                                :day="date"
                                :loading="occupationLoading"
                                :nursery="organization"
                                :opening="mainPayload.opening"
                                :style="
                                    mainPayload.fullDayEvents.count()
                                        ? 'margin-left: 45px; margin-right: 40px'
                                        : 'margin-left: 25px; margin-right: 40px'
                                "
                                :summary="occupationPayload.data"
                            />
                        </div>
                        <div class="tw-mt-2 tw-flex tw-w-full tw-flex-wrap sm:tw-flex-nowrap print:tw-flex-nowrap">
                            <div class="tw-mb-2 tw-w-full tw-max-w-44 sm:tw-mb-0">
                                <MMenu class="tw-w-full">
                                    <MMenuButton class="tw-w-full">
                                        <MButton
                                            class="tw-w-full"
                                            icon-menu
                                            variant="primary"
                                        >
                                            {{
                                                selectedPlanning === 'present' || selectedGroup !== null
                                                    ? __('planning:present_team')
                                                    : __('planning:active_staff')
                                            }}
                                        </MButton>
                                    </MMenuButton>
                                    <MMenuItems>
                                        <MMenuItem @click="changePlanningType('present')">
                                            <FontAwesomeIcon
                                                v-if="selectedPlanning === 'present' || selectedGroup !== null"
                                                class="tw-mr-2"
                                                icon="fa-solid fa-check"
                                            />
                                            {{ __('planning:present_team') }}
                                        </MMenuItem>
                                        <MTooltip
                                            class="tw-w-full"
                                            :hoverable="selectedGroup !== null"
                                            :label="__('planning:group_selected')"
                                        >
                                            <MMenuItem
                                                class="tw-w-full"
                                                :disabled="selectedGroup !== null"
                                                @click="changePlanningType('active')"
                                            >
                                                <FontAwesomeIcon
                                                    v-if="selectedPlanning === 'active' && selectedGroup === null"
                                                    class="tw-mr-2"
                                                    icon="fa-solid fa-check"
                                                />
                                                {{ __('planning:active_staff') }}
                                            </MMenuItem>
                                        </MTooltip>
                                        <MMenuDivider />
                                        <MMenuItem
                                            @click="includeOtherOrganizationsStaffs = !includeOtherOrganizationsStaffs"
                                        >
                                            <FontAwesomeIcon
                                                v-if="includeOtherOrganizationsStaffs"
                                                class="tw-mr-2"
                                                icon="fa-solid fa-check"
                                            />
                                            {{ __('planning:include_multi_organization_staffs') }}
                                        </MMenuItem>
                                        <MMenuDivider v-if="teamFilter && teamFilter.hasQueryResults" />
                                        <TeamFinder
                                            ref="teamFilter"
                                            v-model="selectedTeams"
                                            button-class="tw-w-full"
                                            class="tw-w-full tw-px-1.5"
                                            multi
                                        />
                                    </MMenuItems>
                                </MMenu>
                            </div>
                            <summary-bar-staff
                                class="tw-w-full"
                                :closing="mainPayload.closing"
                                :day="date"
                                :loading="supervisionLoading"
                                :nursery="organization"
                                :opening="mainPayload.opening"
                                :style="
                                    mainPayload.fullDayEvents.count()
                                        ? 'margin-left: 45px; margin-right: 40px'
                                        : 'margin-left: 25px; margin-right: 40px'
                                "
                                :summary="supervisionPayload.data"
                            />
                        </div>
                    </div>
                    <loader
                        v-if="loading"
                        light="true"
                        shadow="false"
                        :title="__('common:loading_dots')"
                    />
                    <div v-show="!loading && mainPayload.processed">
                        <div
                            v-for="(staff, i) in staffs"
                            :key="'staff' + i"
                            class="tw-my-3"
                        >
                            <div
                                v-if="staff.attributes"
                                class="tw-flex tw-w-full tw-flex-wrap tw-items-center sm:tw-flex-nowrap print:tw-flex-nowrap"
                            >
                                <div class="tw-flex tw-w-full tw-min-w-0 tw-items-center sm:tw-w-56 print:tw-w-56">
                                    <BadgeTasks
                                        class="tw-mr-3"
                                        :tasks="tasksByStaffId[staff.getKey()]"
                                    >
                                        <StaffAvatar
                                            class="tw-w-10"
                                            :staff-model="staff"
                                        />
                                    </BadgeTasks>
                                    <div
                                        class="tw-mr-auto tw-truncate tw-font-grandhotel tw-text-2xl tw-capitalize"
                                        :class="staff.genderColor"
                                    >
                                        <router-link
                                            class="tw-text-inherit"
                                            :to="{
                                                name: 'staffs.show',
                                                params: {
                                                    nursery: organization.id,
                                                    staff: staff.id,
                                                },
                                            }"
                                        >
                                            {{ $fullName(staff.attributes) }}
                                        </router-link>
                                    </div>
                                </div>
                                <div class="tw-w-11/12 tw-pl-5 sm:tw-w-full print:tw-w-full">
                                    <PlanningGenerator
                                        :closing="mainPayload.closing"
                                        :day="date"
                                        :events="
                                            staff
                                                .events()
                                                .value()
                                                .all()
                                                .filter(event => event.attributes.date_event === null)
                                        "
                                        :full-day-events="mainPayload.fullDayEvents.all()"
                                        :opening="mainPayload.opening"
                                        :show-menu="$can('update', 'staffs_planning')"
                                        show-note
                                        :staff="staff"
                                        @add="newEvent(date, staff, true, true)"
                                        @push-event="staff.events().value().all().push($event)"
                                    />
                                </div>
                                <div class="tw-w-1/12 tw-pl-1 sm:tw-ml-2 sm:tw-w-8 print:tw-ml-2 print:tw-w-8">
                                    <MButton
                                        v-if="$can('create', 'staffs_planning')"
                                        class="print:!tw-hidden"
                                        variant="primary"
                                        @click="newEvent(date, staff, true)"
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                                    </MButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h1
                    v-if="!loading && staffs.length === 0"
                    class="h1 tw-pt-4 tw-text-center tw-font-grandhotel"
                >
                    {{ __('planning:there_are_no_team_member') }}
                </h1>
            </MBox>

            <EventModal
                v-if="selectedEvent"
                ref="eventModal"
                :event="selectedEvent"
                @add="onEventAdded"
                @delete="onEventDeleted"
                @hidden="selectedEvent = null"
            />
        </template>
    </SimplePageTemplate>
</template>

<script lang="ts">
    import {collect, Epoch} from '@meekohq/lumos';
    import {whenever} from '@vueuse/core';
    import moment from 'moment';
    import {defineComponent, onMounted, onUnmounted, type Ref, ref, watch} from 'vue';

    import {EventBus} from '@/eventBus';
    import BadgeTasks from '@/modules/activity/components/pages/BadgeTasks.vue';
    import useGetTasks from '@/modules/activity/composables/useGetTasks';
    import StaffAvatar from '@/modules/app/components/atoms/avatars/StaffAvatar.vue';
    import SimplePageTemplate from '@/modules/app/components/templates/SimplePageTemplate.vue';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import useModal from '@/modules/app/composables/useModal';
    import useMetrics from '@/modules/app/composables/useRum';
    import useUserFilters, {OutputType} from '@/modules/app/composables/useUserFilters';
    import FilterGroup from '@/modules/family/components/kid/Index/FilterGroup.vue';
    import ManageTemplatesModal from '@/modules/human-resources/components/calendar/CalendarTemplate/Weekly/ManageTemplates.vue';
    import UseTemplateModal from '@/modules/human-resources/components/calendar/CalendarTemplate/Weekly/UseTemplate.vue';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import Line from '@/modules/human-resources/components/calendar/Line.vue';
    import TeamFinder from '@/modules/human-resources/components/team/TeamFinder.vue';
    import useCalendar from '@/modules/human-resources/composables/calendar/useCalendar';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import type TeamModel from '@/modules/human-resources/models/TeamModel';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import StaffSummary from '@/modules/legacy/components/Modules/PlanningGenerator/StaffSummary.vue';
    import Summary from '@/modules/legacy/components/Modules/PlanningGenerator/Summary.vue';
    import OccupationBadge from '@/modules/planning/components/core/OccupationBadge.vue';
    import TopActions from '@/modules/planning/components/staff/TopActions.vue';
    import TopActionsSwitcher from '@/modules/planning/components/staff/TopActionsSwitcher.vue';

    export default defineComponent({
        components: {
            TopActionsSwitcher,
            SimplePageTemplate,
            StaffAvatar,
            TeamFinder,
            'PlanningGenerator': Line,
            Hours,
            EventModal,

            'summary-bar': Summary,
            'summary-bar-staff': StaffSummary,
            TopActions,
            FilterGroup,
            OccupationBadge,
            BadgeTasks,
        },
        setup() {
            const {fromMoment} = useEpoch();
            const teamFilter = ref();
            const {addAction} = useMetrics();
            const {selectedOptions: selectedTeams} = useUserFilters('calendar:staffs:teams', OutputType.array, []);

            const {selectedOptions: selectedPlanning} = useUserFilters(
                'calendar:staffs:planningType',
                OutputType.value,
                'present'
            );

            const {selectedOptions: selectedGroup} = useUserFilters('calendar:staffs:group', OutputType.value, null);

            const {selectedOptions: includeOtherOrganizationsStaffs} = useUserFilters(
                'calendar:staffs:includeOtherOrganizationsStaffs',
                OutputType.value,
                true
            );

            const {
                organization,

                changePlanningType,
                date,
                enumerateDaysBetweenDates,
                opening,
                closing,

                loading,
                getCalendar,

                staffs,

                occupations,
                occupationLoading,
                supervisionLoading,
                weekOccupationRateBar,
                weekOccupationRateBarStaff,

                fullDayEvents,
                selectedEvent,
                newEvent,
                onEventAdded,
                onEventDeleted,
                eventModal,
            } = useCalendar(
                undefined,
                undefined,
                null,
                true,
                true,
                true,
                ref({
                    teams: selectedTeams as Ref<TeamModel[]>,
                    planningType: selectedPlanning as Ref<'present' | 'active'>,
                    group: selectedGroup as Ref<string | null>,
                    includeOtherOrganizationsStaffs: includeOtherOrganizationsStaffs as Ref<boolean>,
                })
            );

            const {
                tasks,
                tasksGroupByModelId: tasksByStaffId,
                getTasks: getTasksRelatedToStaffs,
            } = useGetTasks({
                constrainToResourceModel: StaffModel,
            });

            watch(staffs, newStaffs => {
                getTasksRelatedToStaffs(newStaffs.map(staff => staff.getKey())).then(
                    tasksRelatedToStaffs => (tasks.value = tasksRelatedToStaffs)
                );
            });

            watch(selectedTeams, () => {
                getCalendar();
            });

            // ****** Keep data in cache for performance
            const mainPayload = ref({
                processed: false,
                opening: '',
                closing: '',
                fullDayEvents: collect(),
            });

            onMounted(() => {
                addAction('M_Planning_Staff_Display', {value: 'day'});
                // Update opening and closing hours when the calendar is refreshed
                EventBus.$on('calendar:staffs:refresh', handleRefreshStaffsEvent);
            });

            onUnmounted(() => {
                EventBus.$off('calendar:staffs:refresh', handleRefreshStaffsEvent);
            });

            function handleRefreshStaffsEvent() {
                mainPayload.value.opening = opening();
                mainPayload.value.closing = closing();
                mainPayload.value.fullDayEvents = fullDayEvents(date.value);
            }

            whenever(
                () => loading.value === false,
                () => {
                    mainPayload.value.opening = opening();
                    mainPayload.value.closing = closing();
                    mainPayload.value.fullDayEvents = fullDayEvents(date.value);
                    mainPayload.value.processed = true;
                }
            );

            const occupationPayload = ref({
                processed: false,
                data: {},
            });

            whenever(
                () => loading.value === false && occupationLoading.value === false,
                () => {
                    occupationPayload.value.data = weekOccupationRateBar(date.value);
                    occupationPayload.value.processed = true;
                }
            );

            const supervisionPayload = ref({
                processed: false,
                data: {},
            });

            whenever(
                () => loading.value === false && supervisionLoading.value === false,
                () => {
                    supervisionPayload.value.data = weekOccupationRateBarStaff(date.value);
                    supervisionPayload.value.processed = true;
                }
            );

            function showManageTemplatesModal() {
                useModal({
                    component: ManageTemplatesModal,
                    props: {
                        currentWeek: date.value.week(),
                        staffsFromCurrentWeek: staffs,
                    },
                }).show();
            }

            function showUseTemplateModal() {
                useModal({
                    component: UseTemplateModal,
                    listeners: () => ({
                        'update:plannings': () => {
                            getCalendar();
                        },
                    }),
                }).show();
            }

            return {
                mainPayload,
                occupationPayload,
                supervisionPayload,

                changePlanningType,
                moment,
                Epoch,
                fromMoment,

                organization,

                date,
                enumerateDaysBetweenDates,
                opening,
                closing,

                loading,
                getCalendar,

                teamFilter,
                selectedTeams,
                staffs,
                selectedPlanning,
                selectedGroup,
                includeOtherOrganizationsStaffs,

                occupations,
                occupationLoading,
                supervisionLoading,
                weekOccupationRateBar,
                weekOccupationRateBarStaff,

                fullDayEvents,
                selectedEvent,
                newEvent,
                onEventAdded,
                onEventDeleted,
                eventModal,

                showManageTemplatesModal,
                showUseTemplateModal,
                tasksByStaffId,
            };
        },
    });
</script>
