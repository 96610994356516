import {type App, defineAsyncComponent} from 'vue';

export default function (Vue: App) {
    // Elements
    Vue.component(
        'MBadge',
        defineAsyncComponent(() => import('./components/Elements/MBadge.vue'))
    );
    Vue.component(
        'MMenu',
        defineAsyncComponent(() => import('./components/Elements/MMenu/MMenu.vue'))
    );
    Vue.component(
        'MSubMenu',
        defineAsyncComponent(() => import('./components/Elements/MMenu/MSubMenu.vue'))
    );
    Vue.component(
        'MMenuButton',
        defineAsyncComponent(() => import('./components/Elements/MMenu/MMenuButton.vue'))
    );
    Vue.component(
        'MMenuItems',
        defineAsyncComponent(() => import('./components/Elements/MMenu/MMenuItems.vue'))
    );
    Vue.component(
        'MMenuItem',
        defineAsyncComponent(() => import('./components/Elements/MMenu/MMenuItem.vue'))
    );
    Vue.component(
        'MMenuDivider',
        defineAsyncComponent(() => import('./components/Elements/MMenu/MMenuDivider.vue'))
    );
    Vue.component(
        'MMenuOverlay',
        defineAsyncComponent(() => import('./components/Elements/MMenu/MMenuOverlay.vue'))
    );
    Vue.component(
        'MAvatar',
        defineAsyncComponent(() => import('./components/Elements/MAvatar.vue'))
    );
    Vue.component(
        'MAvatarEditor',
        defineAsyncComponent(() => import('./components/Elements/MAvatarEditor.vue'))
    );
    Vue.component(
        'MButton',
        defineAsyncComponent(() => import('./components/Elements/MButton.vue'))
    );

    // Forms
    Vue.component(
        'MFieldset',
        defineAsyncComponent(() => import('./components/Forms/MFieldset.vue'))
    );
    Vue.component(
        'MForm',
        defineAsyncComponent(() => import('./components/Forms/MForm.vue'))
    );
    Vue.component(
        'MLabel',
        defineAsyncComponent(() => import('./components/Forms/MLabel.vue'))
    );
    Vue.component(
        'MSelectMenu',
        defineAsyncComponent(() => import('./components/Forms/MSelectMenu.vue'))
    );
    Vue.component(
        'MTextarea',
        defineAsyncComponent(() => import('./components/Forms/MTextarea.vue'))
    );

    /**
     * COMPONENTS NOT VALIDATED YET :
     */

    // Legacy
    Vue.component(
        'CModal',
        defineAsyncComponent(() => import('./components/MModal.vue'))
    );
    Vue.component(
        'CTooltip',
        defineAsyncComponent(() => import('./components/MTooltip.vue'))
    );
    Vue.component(
        'CButton',
        defineAsyncComponent(() => import('./components/Elements/MButton.vue'))
    );
    Vue.component(
        'CInput',
        defineAsyncComponent(() => import('./components/MInput.vue'))
    );
    Vue.component(
        'CSkeletonItem',
        defineAsyncComponent(() => import('./components/MSkeleton.vue'))
    );
    Vue.component(
        'CModalPortal',
        defineAsyncComponent(() => import('./components/MPortal.vue'))
    );
    Vue.component(
        'CBadge',
        defineAsyncComponent(() => import('./components/Elements/MBadge.vue'))
    );
    Vue.component(
        'CLabel',
        defineAsyncComponent(() => import('../meeko-ui/components/Forms/MLabel.vue'))
    );

    // Layout
    Vue.component(
        'MBox',
        defineAsyncComponent(() => import('./components/MBox.vue'))
    );

    // Typography
    Vue.component(
        'MPageTitle',
        defineAsyncComponent(() => import('./components/MPageTitle.vue'))
    );
    Vue.component(
        'MHeading',
        defineAsyncComponent(() => import('./components/MHeading.vue'))
    );
    Vue.component(
        'MClamp',
        defineAsyncComponent(() => import('./components/MClamp.vue'))
    );
    Vue.component(
        'MSmall',
        defineAsyncComponent(() => import('./components/MSmall.vue'))
    );

    // Overlay
    Vue.component(
        'MPortal',
        defineAsyncComponent(() => import('./components/MPortal.vue'))
    );
    Vue.component(
        'MModal',
        defineAsyncComponent(() => import('./components/MModal.vue'))
    );
    Vue.component(
        'MTooltip',
        defineAsyncComponent(() => import('./components/MTooltip.vue'))
    );
    Vue.component(
        'MHelpTooltip',
        defineAsyncComponent(() => import('./components/MHelpTooltip.vue'))
    );

    Vue.component(
        'MPopover2',
        defineAsyncComponent(() => import('./components/MPopover2.vue'))
    );

    Vue.component(
        'MPopover2Panel',
        defineAsyncComponent(() => import('./components/MPopover2Panel.vue'))
    );

    Vue.component(
        'MPopover2ClickableReference',
        defineAsyncComponent(() => import('./components/MPopover2ClickableReference.vue'))
    );

    Vue.component(
        'MPopover',
        defineAsyncComponent(() => import('./components/MPopover.vue'))
    );
    Vue.component(
        'MPopoverButton',
        defineAsyncComponent(() => import('./components/MPopoverButton.vue'))
    );
    Vue.component(
        'MPopoverPanel',
        defineAsyncComponent(() => import('./components/MPopoverPanel.vue'))
    );
    Vue.component(
        'MPopoverOverlay',
        defineAsyncComponent(() => import('./components/MPopoverOverlay.vue'))
    );

    Vue.component(
        'MTabs',
        defineAsyncComponent(() => import('./components/MTabs.vue'))
    );
    Vue.component(
        'MTabsItem',
        defineAsyncComponent(() => import('./components/MTabsItem.vue'))
    );

    // Forms
    Vue.component(
        'MButtonSelect',
        defineAsyncComponent(() => import('./components/MButtonSelect.vue'))
    );
    Vue.component(
        'MInput',
        defineAsyncComponent(() => import('./components/MInput.vue'))
    );
    Vue.component(
        'MNumberInput',
        defineAsyncComponent(() => import('./components/MNumberInput.vue'))
    );
    Vue.component(
        'MInputGroup',
        defineAsyncComponent(() => import('./components/MInputGroup.vue'))
    );
    Vue.component(
        'MTimePicker',
        defineAsyncComponent(() => import('./components/MTimePicker.vue'))
    );
    Vue.component(
        'MDatePicker',
        defineAsyncComponent(() => import('./components/MDatePicker/MDatePicker.vue'))
    );
    Vue.component(
        'MMonthPicker',
        defineAsyncComponent(() => import('./components/MMonthPicker.vue'))
    );
    Vue.component(
        'MYearPicker',
        defineAsyncComponent(() => import('./components/MYearPicker.vue'))
    );
    Vue.component(
        'MCurrencyInput',
        defineAsyncComponent(() => import('./components/MCurrencyInput.vue'))
    );

    // StepPanel
    Vue.component(
        'MStepPanel',
        defineAsyncComponent(() => import('./components/MStepPanel/MStepPanel.vue'))
    );
    Vue.component(
        'MStepPanelContent',
        defineAsyncComponent(() => import('./components/MStepPanel/MStepPanelContent.vue'))
    );
    Vue.component(
        'MStepPanelHeader',
        defineAsyncComponent(() => import('./components/MStepPanel/MStepPanelHeader.vue'))
    );
    Vue.component(
        'MStepPanelHeaderItem',
        defineAsyncComponent(() => import('./components/MStepPanel/MStepPanelHeaderItem.vue'))
    );

    // Feedback
    Vue.component(
        'MSkeleton',
        defineAsyncComponent(() => import('./components/MSkeleton.vue'))
    );
    Vue.component(
        'MHoverable',
        defineAsyncComponent(() => import('./components/MHoverable.vue'))
    );

    // Others
    Vue.component(
        'MPromised',
        defineAsyncComponent(() => import('./components/MPromised.vue'))
    );
    Vue.component(
        'MSettingsButton',
        defineAsyncComponent(() => import('./components/MSettingsButton.vue'))
    );

    // Icons
    Vue.component(
        'MIconDownload',
        defineAsyncComponent(() => import('./components/MIconDownload.vue'))
    );
    Vue.component(
        'MIconEdit',
        defineAsyncComponent(() => import('./components/MIconEdit.vue'))
    );
    Vue.component(
        'MIconDelete',
        defineAsyncComponent(() => import('./components/MIconDelete.vue'))
    );

    // Errors
    Vue.component(
        'MErrorMessage',
        defineAsyncComponent(() => import('./components/MErrorMessage.vue'))
    );
    Vue.component(
        'MErrorMessageList',
        defineAsyncComponent(() => import('./components/MErrorMessageList.vue'))
    );
}
