<template>
    <div class="RessourceSelector">
        <ResourceList
            v-if="multi"
            :resources="collectionSelectedResources.all()"
            show-delete
            @delete="unselectResource($event)"
        />
        <div>
            <MPopover2 :bus="bus">
                <MPopover2ClickableReference>
                    <MButton
                        :class="buttonClass"
                        :variant="variant"
                    >
                        <template #left-icons>
                            <FontAwesomeIcon
                                v-if="multi || (collectionSelectedResources.isEmpty() && !multi)"
                                fixed-width
                                icon="fa-solid fa-plus"
                            />
                            <FontAwesomeIcon
                                v-else
                                fixed-width
                                :icon="computedResources[0].icon"
                            />
                        </template>
                        <template #default>
                            {{
                                collectionSelectedResources.isNotEmpty() && !multi
                                    ? computedResources[0].name
                                    : placeholder
                            }}
                        </template>
                    </MButton>
                </MPopover2ClickableReference>
                <MPopover2Panel
                    no-padding
                    placement="bottom-start"
                    @opened="toggleFinderModal(true)"
                    @closed="toggleFinderModal(false)"
                    @click-outside="bus.emit('close')"
                >
                    <div class="ResourcesSelector__popper-container">
                        <ul
                            v-if="showCategory"
                            class="ResourcesSelector__category-list"
                        >
                            <li
                                v-for="category in allowedCategories"
                                :key="category.type"
                            >
                                <ResourcesSelectorCategory
                                    :category="category"
                                    :selected="selectedCategory && category.type === selectedCategory.type"
                                    @selected="selectCategory($event)"
                                />
                            </li>
                        </ul>
                        <component
                            :is="selectedCategory.finder"
                            v-if="selectedCategory && isFinderVisible"
                            v-bind="selectedCategory.finderProps"
                            class="ResourcesSelector__data-list"
                            :inject-query="injectQueryFinder"
                            :model-value="selectedCategoryResources"
                            wrapper="ResourceFinderWrapper"
                            @update:model-value="selectResource($event)"
                        />
                    </div>
                </MPopover2Panel>
            </MPopover2>
        </div>
    </div>
</template>

<script lang="ts">
    import type {Model, ModelCollection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import {useToggle} from '@vueuse/core';
    import _cloneDeep from 'lodash-es/cloneDeep';
    import {computed, defineComponent, onMounted, type PropType, reactive, toRef, watch} from 'vue';

    import ResourcesSelectorCategory from '@/modules/app/components/resource/molecules/ResourcesSelectorCategory.vue';
    import ResourceList from '@/modules/app/components/resource/organisms/ResourceList.vue';
    import useResource from '@/modules/app/composables/useResource';
    import type {AskedCategory} from '@/modules/app/composables/useResourceSelectorCategories';
    import useResourceSelectorCategories from '@/modules/app/composables/useResourceSelectorCategories';
    import useResourceSelectorValue from '@/modules/app/composables/useResourceSelectorValue';
    import __ from '@/modules/app/utils/i18n-facade';
    import CustomerFinder from '@/modules/cashier/components/core/customer/organisms/CustomerFinder.vue';
    import CustomerModel from '@/modules/cashier/models/CustomerModel';
    import KidFinder from '@/modules/family/components/kid/KidFinder.vue';
    import MemberFinder from '@/modules/family/components/member/MemberFinder.vue';
    import KidModel from '@/modules/family/models/KidModel';
    import MemberModel from '@/modules/family/models/MemberModel';
    import StaffModel from '@/modules/human-resources/models/StaffModel';
    import {defineFloatingContextBus} from '@/modules/meeko-ui/components/MFloatingContext';
    import OrganizationFinder from '@/modules/organization/components/OrganizationFinder.vue';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel';
    import RegistrationFinder from '@/modules/registration/components/molecules/RegistrationFinder.vue';
    import RegistrationModel from '@/modules/registration/models/RegistrationModel';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';

    export default defineComponent({
        components: {
            ResourcesSelectorCategory,
            OrganizationFinder,
            KidFinder,
            MemberFinder,
            StaffFinder,
            CustomerFinder,
            RegistrationFinder,
            ResourceList,
        },
        props: {
            modelValue: {
                type: Object as PropType<ModelCollection<Model> | Model>,
                default: () => reactive(collect()),
            },
            buttonClass: {
                type: String,
                default: '',
            },
            variant: {
                type: String,
                default: 'light',
            },
            categories: {
                type: Array as PropType<AskedCategory[]>,
                default: () =>
                    reactive([
                        {
                            type: new KidModel().getType(),
                            finderProps: {
                                hasActiveContract: true,
                                onlyUserOrganizations: true,
                                hideSelectedOption: true,
                            },
                        },
                        {
                            type: new MemberModel().getType(),
                            finderProps: {
                                hasActiveContract: true,
                                onlyUserOrganizations: true,
                                hideSelectedOption: true,
                            },
                        },
                        {
                            type: new StaffModel().getType(),
                            finderProps: {
                                hasActiveContract: true,
                                onlyUserOrganizations: true,
                                hideSelectedOption: true,
                            },
                        },
                        {
                            type: new RegistrationModel().getType(),
                            finderProps: {
                                ongoingRegistrations: true,
                                onlyUserOrganizations: true,
                                hideSelectedOption: true,
                            },
                        },
                        {
                            type: new CustomerModel().getType(),
                            finderProps: {
                                hasActiveContract: true,
                                onlyUserOrganizations: true,
                                hideSelectedOption: true,
                            },
                        },
                        {
                            type: new OrganizationModel().getType(),
                            finderProps: {onlyUserOrganizations: true, hideSelectedOption: true},
                        },
                    ]),
            },
            multi: {
                type: Boolean,
                default: true,
            },
            placeholder: {
                type: String,
                default: () => __('common:actions.select'),
            },
            showCategory: {
                type: Boolean,
                default: () => true,
            },
        },
        emits: ['update:modelValue', 'unselect', 'select'],
        setup(props, context) {
            const bus = defineFloatingContextBus();

            const [isFinderVisible, toggleFinderModal] = useToggle(false);
            const askedCatergories = toRef(props, 'categories');
            const {allowedCategories, selectedCategory, selectCategory} =
                useResourceSelectorCategories(askedCatergories);

            watch(askedCatergories, () => {
                const resourceSelectorCategories = useResourceSelectorCategories(askedCatergories);
                selectedCategory.value = resourceSelectorCategories.selectedCategory.value;
            });

            const value = toRef(props, 'modelValue');
            const isMultiSelect = toRef(props, 'multi');
            const {
                selectedCategoryResources,
                collectionSelectedResources,
                selectedResources,
                selectResource,
                unselectResource,
            } = useResourceSelectorValue(value, allowedCategories, selectedCategory, isMultiSelect, context.emit, bus);

            // Filter finder query to remove already selected resources
            const injectQueryFinder = computed(() => {
                const excludedResources = collectionSelectedResources.value.filter(
                    item => item.getType() === selectedCategory.value.type
                );

                const excludedIds = excludedResources.pluck('id');

                let query;

                if (selectedCategory.value.finderProps?.injectQuery) {
                    // Clone because if not, the whereNotIn will be added to the original query
                    query = _cloneDeep(selectedCategory.value.finderProps.injectQuery);
                }

                if (excludedIds.count()) {
                    if (!query) {
                        query = excludedResources.first().newQuery();
                    }
                    query.whereNotIn('id', excludedIds.toArray());
                }

                return query;
            });

            const arraySelectedResources = computed(() => collectionSelectedResources.value.all());
            const {computedResources, init} = useResource(arraySelectedResources);

            onMounted(async () => {
                await init();
            });

            return {
                allowedCategories,
                selectedCategoryResources,
                collectionSelectedResources,
                selectedCategory,
                injectQueryFinder,
                selectedResources,
                computedResources,
                selectCategory,
                selectResource,
                unselectResource,
                bus,
                isFinderVisible,
                toggleFinderModal,
            };
        },
    });
</script>

<style scoped>
    .RessourceSelector {
        @apply tw-flex tw-flex-col tw-items-start tw-gap-2;
    }

    .ResourcesSelector__popper-container {
        @apply tw-flex tw-flex-row;
    }

    .ResourcesSelector__category-list {
        @apply tw-p-2;
        @apply tw-flex tw-flex-col tw-gap-1;
    }

    .ResourcesSelector__data-list {
        @apply tw-border-l tw-border-gray-200;
    }

    .ResourcesSelector__category-list-item--active {
        @apply tw-bg-blue-100;
    }
</style>
