import _cloneDeep from 'lodash-es/cloneDeep';

export class FamilyAppSettingsEntity {
    constructor(
        public organizationId: string,
        public phone: string | undefined | null,
        public email: string | undefined | null,
        public website: string | undefined | null,
        public displayStaffLastName: boolean
    ) {}

    public copy() {
        return _cloneDeep(this);
    }

    public sanitizeWebsite() {
        this.website = this.website.replace(/ /g, '');
    }
}
