<template>
    <div>
        <div
            v-for="(week, key) in contract.recurrent_weeks"
            :key="'week_contract' + key"
            class="tw-mb-12"
        >
            <MHeading>
                {{ __('planning:reference_week_number', {week: week}) }}
                <MHeading
                    variant="gray"
                    level="h3"
                    class="tw-inline"
                >
                    -
                    {{
                        __('common:starting_from_with_date', {
                            date: moment(contract.started_at)
                                .clone()
                                .startOf('week')
                                .add(key, 'weeks')
                                .format('DD/MM/YYYY'),
                        })
                    }}
                </MHeading>
            </MHeading>
            <hr class="hr" />
            <div class="tw-mb-2 tw-table tw-w-full">
                <div
                    class="tw-hidden sm:tw-block"
                    style="padding-left: 85px; padding-right: 65px"
                >
                    <hours
                        :closing="closing"
                        :opening="opening"
                    />
                </div>
            </div>
            <div
                v-for="(day, i) in enumerateDaysBetweenDates(
                    weekStart.clone().add(week, 'weeks').format(),
                    weekEnd.clone().add(week, 'weeks').format()
                )"
                :key="'day_contract' + i"
                class="tw-mb-2 tw-table tw-w-full"
            >
                <div
                    class="day tw-float-none tw-mb-1 tw-font-display tw-capitalize sm:tw-float-left sm:tw-mb-0 sm:tw-pr-6 sm:tw-text-right"
                >
                    <MTooltip :label="getTotalDayPlannings(day)">
                        {{ day.format('dddd') }}
                    </MTooltip>
                </div>
                <div class="planning tw-float-none sm:tw-float-left sm:tw-pr-4">
                    <planning-generator
                        :closing="closing"
                        :day="day"
                        :events="events"
                        inner-text
                        :nursery="nursery"
                        :opening="opening"
                        :show-clock="false"
                        :show-menu="$can('update', 'kids_contracts')"
                        :user="user"
                    />
                </div>
                <div
                    v-if="!disabled && $can('update', 'kids_contracts')"
                    class="add tw-float-none sm:tw-float-right sm:tw-pr-4"
                >
                    <MButton
                        variant="primary"
                        @click="addPlage(week, day)"
                    >
                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                    </MButton>
                </div>
            </div>
        </div>
        <div
            v-if="!disabled && $can('update', 'kids_contracts')"
            class="tw-text-center"
        >
            <MButton
                variant="primary"
                icon-plus
                @click="contract.recurrent_weeks++"
            >
                {{ __('common:add_week') }}
            </MButton>
            <MButton
                v-if="contract.recurrent_weeks > 1"
                variant="danger"
                class="tw-ml-2"
                icon-trash
                @click="removeWeek"
            >
                {{ __('common:remove_week') }}
            </MButton>
        </div>
    </div>
</template>

<script>
    import _clone from 'lodash-es/clone';
    import _concat from 'lodash-es/concat';
    import _find from 'lodash-es/find';
    import moment from 'moment';

    import {EventBus} from '@/eventBus';
    import useModal from '@/modules/app/composables/useModal';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import {getPlanningEventsFromKidContractOrRegistration} from '@/modules/legacy/libs/Planning.ts';
    import {nursery} from '@/modules/legacy/mixins/nursery';
    import EditPlanning from '@/modules/planning/components/Modal/EditPlanning.vue';
    import NewPlanning from '@/modules/planning/components/Modal/NewPlanning.vue';

    export default {
        components: {
            hours: Hours,
        },
        mixins: [nursery],
        props: ['nursery', 'contract', 'disabled', 'user'],
        data: () => ({
            planningOnEdition: {},
            addOnWeek: 1,
            addOnDay: '',
            events: [],
        }),
        computed: {
            moment() {
                return moment;
            },
            opening() {
                return this.$getOpening(this.nursery.openingHours, false, false, this.events);
            },
            closing() {
                return this.$getClosing(this.nursery.openingHours, false, false, this.events);
            },
            exactOpening() {
                return this.$getOpening(this.nursery.openingHours, true, true, this.events);
            },
            exactClosing() {
                return this.$getClosing(this.nursery.openingHours, true, true, this.events);
            },
            weekStart() {
                return moment(this.contract.started_at).startOf('week').subtract(1, 'week');
            },
            weekEnd() {
                return this.weekStart.clone().endOf('week');
            },
        },
        watch: {
            'contract.plannings': {
                handler() {
                    this.computeEvents();
                },
                deep: true,
                immediate: true,
            },
        },
        mounted() {
            EventBus.$on('clicked:event', this.handleClickedEvent);
        },
        beforeUnmount() {
            EventBus.$off('clicked:event', this.handleClickedEvent);
        },
        methods: {
            handleClickedEvent(segment) {
                if (this.disabled) {
                    return;
                }

                this.planningOnEdition = this.contract.plannings.find(planning => {
                    return planning.id === segment.id;
                });

                const editPlanningModal = useModal({
                    component: EditPlanning,
                    props: {
                        planning: this.planningOnEdition,
                        planningModel: this.contract,
                    },
                });
                editPlanningModal.show();
            },
            computeEvents() {
                let events = [];
                for (let i = 1; i <= this.contract.recurrent_weeks; i++) {
                    this.enumerateDaysBetweenDates(
                        this.weekStart.clone().add(i, 'weeks').format(),
                        this.weekEnd.clone().add(i, 'weeks').format()
                    ).forEach(day => {
                        const contract = _clone(this.contract);
                        contract.plannings = _clone(this.contract).plannings.filter(planning => {
                            return planning.type === 'recurrent';
                        });
                        events = _concat(
                            events,
                            getPlanningEventsFromKidContractOrRegistration(day, contract, undefined, {onlyHours: true})
                        );
                    });
                }
                this.events = events.filter(event => event.event_type === 'planning-recurrent');
            },
            removeWeek() {
                this.contract.recurrent_weeks--;
                this.contract.plannings = this.contract.plannings.filter(
                    planning => planning.type === 'recurrent' && planning.week <= this.contract.recurrent_weeks
                );
            },
            enumerateDaysBetweenDates(start, end) {
                const dates = [];

                const currDate = moment(start).startOf('day');
                const lastDate = moment(end).startOf('day');

                dates.push(currDate.clone());
                while (currDate.add(1, 'days').diff(lastDate) <= 0) {
                    const day = currDate.clone().locale('en').format('ddd').toLowerCase();
                    if (day === 'sat' || day === 'sun') {
                        const hasDay = _find(this.nursery.openingHours, {day});
                        hasDay ? dates.push(currDate.clone()) : null;
                    } else {
                        dates.push(currDate.clone());
                    }
                }

                return dates;
            },
            addPlage(week, day) {
                this.addOnWeek = week;
                this.addOnDay = day.locale('en').day();

                // We use the last event to copy its start and end time, otherwise we use the organization opening and
                // closing hours.

                const opening = this.events?.length
                    ? this.events[this.events.length - 1].start_time
                    : this.exactOpening;
                const closing = this.events?.length ? this.events[this.events.length - 1].end_time : this.exactClosing;

                const newPlanningModal = useModal({
                    component: NewPlanning,
                    props: {
                        closing,
                        planningModel: this.contract,
                        day: this.addOnDay,
                        opening,
                        type: 'recurrent',
                        week: this.addOnWeek,
                    },
                });
                newPlanningModal.show();
            },

            getTotalDayPlannings(day) {
                let total = 0;
                const startDay = day.clone().unix();
                const endDay = day.clone().endOf('day').unix();
                this.events.forEach(event => {
                    if (event.started_at > startDay && event.ended_at < endDay) {
                        total += (event.ended_at - event.started_at) / 60;
                    }
                });

                return this.minutesToHours(total);
            },
            minutesToHours(min) {
                const days = moment.duration(min, 'minutes').days();
                let hours = moment.duration(min, 'minutes').hours();
                const minutes = moment.duration(min, 'minutes').minutes();
                days ? (hours += days * 24) : null;

                return minutes ? hours + 'h' + minutes : hours + 'h';
            },
        },
    };
</script>

<style scoped>
    .name {
        .avatar {
            position: absolute;
            top: -12px;
            left: -10px;
            width: 50px;
            height: 50px;
        }
    }

    @media screen(sm) {
        .day {
            width: 100px;
        }

        .planning {
            width: calc(100% - 100px - 35px);
        }

        .add {
            width: 35px;
        }
    }
</style>
