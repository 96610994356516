<template>
    <div>
        <slot
            name="clickable"
            :toggle="togglePopover"
        />
        <Teleport
            v-if="isInjected"
            to="#topbar-widget"
        >
            <transition
                appear
                name="transition-widget"
                @after-enter="onAfterEnter"
                @after-leave="onAfterLeave"
            >
                <div
                    v-if="isVisible"
                    ref="target"
                    class="WidgetPortal"
                >
                    <MFloatingContainer
                        key="widget-portal"
                        :context="context"
                    >
                        <slot :toggle="togglePopover" />
                    </MFloatingContainer>
                </div>
            </transition>
        </Teleport>
    </div>
</template>

<script lang="ts">
    import {onClickOutside} from '@vueuse/core';
    import {computed, defineComponent, nextTick, provide, ref} from 'vue';

    import MFloatingContainer from '@/modules/meeko-ui/components/MFloatingContainer.vue';
    import {defineFloatingContext} from '@/modules/meeko-ui/components/MFloatingContext';

    export default defineComponent({
        components: {MFloatingContainer},
        props: {
            clickOutside: {
                type: Boolean,
                default: true,
            },
        },
        setup(props) {
            const target = ref(null);

            const context = defineFloatingContext();

            provide('MFloatingContext', context);

            const {childrens, isInjected, isReady, isVisible, closePopover, togglePopover} = context;

            const ignored = computed(() => [...childrens.value]);

            onClickOutside(
                target,
                () => {
                    if (props.clickOutside) {
                        closePopover();
                    }
                },
                {ignore: ignored}
            );

            function onAfterEnter() {
                nextTick(() => {
                    isReady.value = true;
                });
            }

            function onAfterLeave() {
                isInjected.value = false;
                isReady.value = false;
            }

            return {
                isInjected,
                isVisible,
                togglePopover,
                target,
                context,
                onAfterEnter,
                onAfterLeave,
            };
        },
    });
</script>

<style>
    .WidgetPortal > * {
        @apply tw-pointer-events-auto;
    }
</style>

<style scoped>
    .WidgetPortal {
        @apply tw--left-1 tw--right-1 tw-top-1 sm:tw--left-2 sm:tw--right-2;
        max-height: calc(100vh - 140px);
        @apply tw-pointer-events-none tw-absolute tw-flex tw-justify-end;
    }

    .transition-widget {
        &-enter-active,
        &-leave-active {
            @apply tw-transition-all tw-duration-300 tw-ease-in-out;
        }

        &-enter-from {
            @apply tw-translate-x-16 tw-opacity-0;
        }

        &-enter-to {
            @apply tw-translate-x-0 tw-translate-y-0 tw-opacity-100;
        }

        &-leave-from {
            @apply tw-translate-x-0 tw-translate-y-0 tw-opacity-100;
        }

        &-leave-to {
            @apply tw-translate-x-16 tw-opacity-0;
        }
    }
</style>
