<template>
    <list-wrapper :title="__('hr:event_type')">
        <template
            v-if="loader.isDone()"
            #action
        >
            <MTooltip :label="__('common:import_from_another_organization')">
                <MButton @click="$refs.importModal.show()">
                    <template #left-icons>
                        <FontAwesomeIcon icon="fa-solid fa-download" />
                    </template>
                    {{ __('common:actions.import') }}
                </MButton>
            </MTooltip>
            <MMenu>
                <MMenuButton>
                    <MButton
                        icon-plus
                        :label="__('hr:add_event_type')"
                        variant="primary"
                    />
                </MMenuButton>
                <MMenuItems>
                    <MMenuItem
                        v-for="(calendar, index) in calendars"
                        :key="index"
                        :label="calendar.attributes.name"
                        @click="showCreateModal(calendar)"
                    />
                </MMenuItems>
            </MMenu>
        </template>
        <EventTypeList
            v-if="loader.isDone()"
            ref="list"
            :calendars="calendars"
            :event-types-relations-constraint="eventTypesRelationsConstraint"
        />
        <loader
            v-else
            light="false"
            shadow="false"
            size="md"
            :title="__('hr:event_type_loading')"
        />
        <CreateEventTypeModal
            v-if="loader.isDone()"
            ref="createModal"
            :calendar-model="selectedCalendar"
            @created="$refs.list.addEventType($event)"
        />
        <ImportEventTypeModal
            ref="importModal"
            @imported="fetchCalendars"
        />
    </list-wrapper>
</template>

<script lang="ts">
    import {collect, type QueryBuilder} from '@meekohq/lumos';
    import {defineComponent, reactive, ref, watch} from 'vue';

    import useManager from '@/modules/app/composables/useManager';
    import EventTypeList from '@/modules/human-resources/components/event-type/List/EventTypeList.vue';
    import CreateEventTypeModal from '@/modules/human-resources/components/event-type/Modals/CreateEventTypeModal.vue';
    import ImportEventTypeModal from '@/modules/human-resources/components/event-type/Modals/ImportEventTypeModal.vue';
    import CalendarModel from '@/modules/human-resources/models/CalendarModel';
    import EventTypeModel from '@/modules/human-resources/models/EventTypeModel';
    import Loader from '@/modules/legacy/helpers/loader.helper';

    export default defineComponent({
        components: {
            ImportEventTypeModal,
            EventTypeList,
            CreateEventTypeModal,
        },
        props: {},
        setup() {
            const loader = reactive(new Loader());
            const createModal = ref();
            const selectedCalendar = ref<CalendarModel>(new CalendarModel());
            const calendars = ref(collect<CalendarModel>());
            const {activeOrganization} = useManager();

            // create a contraints for EventType Relation
            function eventTypesRelationsConstraint(query: QueryBuilder<EventTypeModel>): void {
                query
                    .whereHas(new EventTypeModel().organizations(), q => q.scope('active'))
                    .with(new EventTypeModel().organizationsPivots())
                    .with(new EventTypeModel().calendar())
                    .withCount(new EventTypeModel().events())
                    .withCount(new EventTypeModel().templateEvents())
                    .orderBy('name');
            }

            const fetchCalendars = async function () {
                loader.start();

                // Fetch calendar with EventType with its contraints
                calendars.value = await CalendarModel.query()
                    .with(new CalendarModel().eventTypes(), eventTypesRelationsConstraint)
                    .get();

                loader.stop();
            };

            function showCreateModal(calendar) {
                selectedCalendar.value = calendar;
                createModal.value.show();
            }

            watch(
                activeOrganization,
                () => {
                    fetchCalendars();
                },
                {deep: true, immediate: true}
            );

            return {
                eventTypesRelationsConstraint,
                fetchCalendars,
                calendars,
                showCreateModal,
                loader,
                createModal,
                selectedCalendar,
            };
        },
    });
</script>
