<template>
    <nav class="Sidebar">
        <div class="Sidebar__header">
            <div class="Sidebar__logo">
                <img
                    alt="Meeko"
                    class="meeko-snowglobe"
                    :src="allows('support') ? SupercoonSupport : SupercoonImage"
                />
                <ChristmasCounter />
            </div>
        </div>
        <div
            v-if="!disabled && ready && user.nurseries.length > 1"
            class="Sidebar__organization-wrapper"
        >
            <SwapOrganizationButton
                class="Sidebar__swap-button"
                :nursery="nursery"
                :user="user"
            />
        </div>
        <div class="Sidebar__tools-container">
            <MagicIndexTransition>
                <div v-if="disabled">
                    <h1 class="h1 hidden-on-untoggled-mobile tw-hidden tw-text-center tw-font-grandhotel sm:tw-block">
                        meeko
                    </h1>
                    <div
                        class="hidden-on-untoggled-mobile tw-p-2 tw-text-center tw-font-display tw-text-primary-950/50 sm:tw-text-sm lg:tw-text-base"
                        role="alert"
                    >
                        {{ __('app:select_organization_to_discover') }}
                    </div>
                </div>
                <ToolsMenu
                    v-else-if="ready"
                    class="Sidebar__tools"
                    :nursery="nursery"
                    :user="user"
                />
                <MagicIndexLoader v-else />
            </MagicIndexTransition>
        </div>
    </nav>
</template>

<script>
    import SupercoonSupport from '@/assets/images/special/Meeko_Snowglobe.png';
    import SupercoonImage from '@/assets/images/special/Meeko_Snowglobe.png';
    import ToolsMenu from '@/modules/app/components/layouts/Sidebar/ToolsMenu.vue';
    import SwapOrganizationButton from '@/modules/app/components/layouts/SwapOrganizationButton.vue';
    import useAbility from '@/modules/app/composables/useAbility';
    import MagicIndexLoader from '@/modules/magic-index/components/atoms/MagicIndexLoader.vue';
    import MagicIndexTransition from '@/modules/magic-index/components/atoms/MagicIndexTransition.vue';
    import ChristmasCounter from './Sidebar/special-events/ChristmasCounter.vue';

    export default {
        components: {
            MagicIndexTransition,
            MagicIndexLoader,
            SwapOrganizationButton,
            ToolsMenu,
            ChristmasCounter,
        },
        props: ['disabled', 'ready', 'user', 'nursery'],
        data: () => ({
            SupercoonImage,
            SupercoonSupport,
        }),
        computed: {
            allows() {
                return useAbility().allows;
            },
        },
    };
</script>

<style scoped>
    @keyframes tilt-n-move-shaking {
        0% {
            transform: translate(0, 0) rotate(0deg) scale(1.1);
        }
        25% {
            transform: translate(1px, 1px) rotate(10deg) scale(1.1);
        }
        50% {
            transform: translate(0, 0) rotate(0eg) scale(1.1);
        }
        75% {
            transform: translate(-1px, 1px) rotate(-10deg) scale(1.1);
        }
        100% {
            transform: translate(0, 0) rotate(0deg) scale(1.1);
        }
    }

    .Sidebar__logo:hover {
        .meeko-snowglobe {
            transform-origin: center 80%;
            animation: tilt-n-move-shaking 0.3s 2 ease-in;
        }
    }

    .Sidebar {
        @apply tw-sticky tw-inset-x-0 tw-bottom-0;
        @apply tw-flex tw-flex-col;
        @apply tw-bg-white tw-py-2;
        @apply tw-border-r tw-border-primary-900/10;

        @media screen(sm) {
            @apply tw-inset-0 tw-bg-transparent;
            @apply tw-h-screen tw-shrink-0;
        }

        @media screen(lg) {
            @apply tw-pb-2;
        }

        @apply tw-transition-all;
    }

    .Sidebar__header {
        @apply tw-hidden sm:tw-flex;
        @apply tw-items-center tw-p-2 sm:tw-flex-col;
    }

    .Sidebar__logo {
        @apply tw-relative;

        img {
            width: 100px;
        }
    }

    .Sidebar__organization-wrapper {
        @apply tw-flex tw-hidden tw-justify-between tw-gap-2 tw-px-3 tw-pb-1;

        @media screen(lg) {
            @apply tw-flex tw-flex-col;
        }
    }

    .Sidebar__swap-button {
        @apply tw-my-2 tw-w-full;
    }

    .Sidebar__tools-container {
        @apply tw-flex tw-overflow-auto sm:tw-flex-col;

        @apply tw-pr-20 sm:tw-pr-0;

        ::-webkit-scrollbar {
            @apply tw-hidden;
        }

        @media screen(sm) {
            &:hover::-webkit-scrollbar {
                @apply tw-absolute tw-block tw-h-1.5 tw-w-1.5;
            }

            /* Track */
            &:hover::-webkit-scrollbar-track {
                @apply tw-rounded-full tw-bg-primary-700/10;
            }

            /* Handle */
            &:hover::-webkit-scrollbar-thumb {
                @apply tw-rounded-full tw-bg-primary-700/10;
            }

            /* Handle on hover */
            &:hover::-webkit-scrollbar-thumb:hover {
                @apply tw-bg-primary-700/40;
            }
        }
    }

    .Sidebar__tools {
        @apply tw-min-h-0 tw-flex-auto tw-pb-1;

        @media screen(sm) {
            @apply tw-px-1 tw-pb-0;
        }

        @media screen(lg) {
            @apply tw-px-2 tw-pb-0;
        }
    }
</style>
