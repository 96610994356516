<template>
    <small class="MSmall">
        <slot />
    </small>
</template>

<style scoped>
    .MSmall {
        @apply tw-text-sm;
        @apply tw-text-gray-500;
    }
</style>
