<template>
    <div class="CustomerHeroPropertiesLine">
        <div class="CustomerHeroPropertiesLine__title">
            {{ title }}
        </div>
        <div class="CustomerHeroPropertiesLine__value">
            <slot v-if="hasValue">
                {{ modelValue }}
            </slot>
            <slot
                v-else
                name="fallback"
            >
                {{ __('common:not_filled') }}
            </slot>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        components: {},
        props: {
            hasValue: {type: Boolean, default: true},
            title: {type: String, required: true},
            modelValue: {type: String, default: undefined},
        },
    });
</script>

<style scoped>
    .CustomerHeroPropertiesLine {
        @apply tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2 md:tw-justify-start;
    }

    .CustomerHeroPropertiesLine__title {
        @apply tw-shrink-0;
        @apply tw-w-32;
        @apply tw-truncate;
        @apply tw-text-gray-500;
        @apply tw-font-display tw-antialiased;
        @apply tw-text-base tw-leading-tight;
        @apply tw-font-medium;
    }

    .CustomerHeroPropertiesLine__value {
        @apply tw-truncate;
    }
</style>
