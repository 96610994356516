<template>
    <MModal
        :modal="modal"
        :header-title="__('planning:manage_typical_week')"
        size="5xl"
        @hidden="onHidden"
        @shown="onShown"
    >
        <loader
            v-if="weekTemplatesLoading"
            light="true"
            shadow="false"
            size="sm"
            :title="__('common:loading_dots')"
        />
        <div v-else>
            <div>
                <div class="tw-flex tw-flex-wrap">
                    <MFieldset
                        v-if="filteredWeekTemplates.length"
                        class="tw-mb-3 tw-w-full sm:tw-w-1/3"
                    >
                        <MPopover2>
                            <MPopover2ClickableReference class="tw-w-full tw-min-w-0">
                                <MButton
                                    class="tw-w-full"
                                    align="left"
                                    icon-sort
                                >
                                    <template
                                        v-if="
                                            selectedWeekTemplate.attributes.staff_id !== staff.id &&
                                            selectedWeekTemplate.staff().value()
                                        "
                                    >
                                        {{
                                            selectedWeekTemplate.staff().value().fullname +
                                            ' - ' +
                                            selectedWeekTemplate.templateName
                                        }}
                                    </template>
                                    <template v-else>
                                        {{ selectedWeekTemplate.templateName }}
                                    </template>
                                </MButton>
                            </MPopover2ClickableReference>
                            <MPopover2Panel v-slot="{close}">
                                <div class="tw-flex tw-h-64 tw-flex-col tw-gap-8 tw-overflow-y-auto">
                                    <CList>
                                        <CListRow
                                            v-for="(weekTemplate, i) in filteredWeekTemplates.filter(
                                                item => item.attributes.template_id === null
                                            )"
                                            :key="'weekTemplate' + i"
                                            @click="selectTemplate(weekTemplate, close)"
                                        >
                                            <template
                                                v-if="
                                                    weekTemplate.attributes.staff_id !== staff.id &&
                                                    weekTemplate.staff().value()
                                                "
                                            >
                                                {{
                                                    weekTemplate.staff().value().fullname +
                                                    ' - ' +
                                                    weekTemplate.attributes.name
                                                }}
                                            </template>
                                            <template v-else>
                                                {{ weekTemplate.attributes.name }}
                                            </template>
                                        </CListRow>
                                        <template
                                            v-if="
                                                filteredWeekTemplates.filter(
                                                    item => item.attributes.template_id !== null
                                                ).length
                                            "
                                        >
                                            <MMenuDivider />
                                            <div class="tw-px-1.5 tw-py-2 tw-text-sm tw-text-gray-500">
                                                {{ __('hr_calendar:recovery_from_global_weeks_colon') }}
                                            </div>
                                        </template>
                                        <CListRow
                                            v-for="(weekTemplate, j) in filteredWeekTemplates.filter(
                                                item => item.attributes.template_id !== null
                                            )"
                                            :key="'weekTemplateFromGlobal' + j"
                                            @click="selectTemplate(weekTemplate, close)"
                                        >
                                            <template
                                                v-if="
                                                    weekTemplate.attributes.staff_id !== staff.id &&
                                                    weekTemplate.staff().value()
                                                "
                                            >
                                                {{
                                                    weekTemplate.staff().value().fullname +
                                                    ' - ' +
                                                    weekTemplate.templateName
                                                }}
                                            </template>
                                            <template v-else>
                                                {{ weekTemplate.templateName }}
                                            </template>
                                        </CListRow>
                                    </CList>
                                </div>
                            </MPopover2Panel>
                        </MPopover2>
                    </MFieldset>
                    <div class="tw-ml-2">
                        <CTooltip placement="bottom-start">
                            <StaffFinder
                                :excluded-ids="[staff.getKey()]"
                                only-user-organizations
                                @update:model-value="importTemplatesFromStaff"
                            >
                                <template #trigger="{toggle}">
                                    <MButton @click="toggle">
                                        <template #left-icons>
                                            <FontAwesomeIcon icon="fa-duotone fa-address-book" />
                                        </template>
                                        {{ __('common:actions.import') }}
                                    </MButton>
                                </template>
                            </StaffFinder>
                            <template #content>
                                {{ __('hr_calendar:import_and_use_another_person_week') }}
                            </template>
                        </CTooltip>
                    </div>
                </div>

                <div
                    v-if="!filteredWeekTemplates.length"
                    class="tw-p-3"
                >
                    <h5 class="h5 tw-mb-0 tw-text-center">
                        {{ __('hr_calendar:no_week_templates') }}
                    </h5>
                </div>

                <template v-else>
                    <loader
                        v-if="calendarLoading"
                        light="true"
                        shadow="false"
                        size="sm"
                        :title="__('common:loading_dots')"
                    />

                    <div
                        v-else
                        class="tw-mt-2 tw-rounded-lg tw-border tw-border-gray-200 tw-p-3"
                    >
                        <div class="tw-mb-3 tw-text-center tw-text-sm tw-text-gray-600">
                            {{ __('hr_calendar:update_typical_week_before_applying_colon') }}
                        </div>
                        <div
                            class="tw-mb-2 tw-hidden tw-w-full sm:tw-block"
                            style="padding-left: 44px; padding-right: 55px"
                        >
                            <hours
                                :closing="closing"
                                :opening="opening"
                            />
                        </div>

                        <div
                            v-for="(day, i) in daysOfWeek"
                            :key="'day_save_week' + i"
                            class="tw-mb-2 tw-flex tw-w-full tw-flex-wrap tw-items-center sm:tw-flex-nowrap"
                        >
                            <div class="tw-w-full sm:tw-w-16">
                                <CTooltip>
                                    <MButton
                                        class="tw-capitalize"
                                        variant="hover"
                                    >
                                        {{ fromMoment(day).weekdayShort }}
                                    </MButton>
                                    <template #content>
                                        <div class="tw-min-w-36 tw-text-center tw-text-base tw-text-gray-600">
                                            {{ __('common:total') }}
                                            <CBadge
                                                class="tw-mr-1"
                                                size="sm"
                                                variant="cyan"
                                            >
                                                {{ totalPlannings(day) }}
                                            </CBadge>
                                        </div>
                                    </template>
                                </CTooltip>
                            </div>

                            <div class="tw-w-11/12 sm:tw-w-full">
                                <PlanningGenerator
                                    :auto-save="false"
                                    :closing="closing"
                                    create-with-modal
                                    :day="day"
                                    is-template
                                    :opening="opening"
                                    :show-clock="false"
                                    show-note
                                    :template-events="events"
                                    :week-template="selectedWeekTemplate.id"
                                    @push-event-template="
                                        selectedWeekTemplate.planningEvents().value().all().push($event)
                                    "
                                />
                            </div>
                            <div class="tw-w-1/12 tw-pl-2 sm:tw-ml-2 sm:tw-w-8">
                                <MButton
                                    :round="true"
                                    size="sm"
                                    variant="success"
                                    @click="newEvent(day)"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-plus" />
                                </MButton>
                            </div>
                        </div>

                        <CCenter class="tw-mt-3">
                            <EventTotals
                                :contract="
                                    staff.activeContract()
                                        ? `${staff.activeContract().attributes.hours_per_week}h`
                                        : '0h'
                                "
                                :forecast="totalWeekPlannings"
                                :real="null"
                            />
                        </CCenter>
                    </div>

                    <div class="tw-mb-3 tw-mt-9 tw-text-base tw-font-semibold tw-text-gray-600">
                        {{ __('hr_calendar:replace_weeks_by_week_template_colon') }}
                    </div>

                    <SelectWeeks v-model:selected-weeks="selectedWeeks" />
                    <hr class="hr" />
                    <CHStack>
                        <CCheckbox v-model="deleteRealEvents">
                            {{ __('hr_calendar:delete_real_events_in_selected_weeks') }}
                        </CCheckbox>
                    </CHStack>
                </template>
            </div>

            <EventModal
                v-if="selectedEvent"
                ref="modalEditEvent"
                :event="selectedEvent"
                is-template
                soft-update
                @add="onEventAdded"
                @delete="onEventDeleted"
                @hidden="selectedEvent = null"
            />
        </div>
        <div class="tw-mt-4">
            <FeatureFlag name="enable-new-template-service">
                <InvalidEventErrors :error-handler="invalidEventErrors" />
                <template #disabled>
                    <FailedEventsAlert :failed-events="failedEvents" />
                </template>
            </FeatureFlag>
        </div>
        <template #footer-end="{hide}">
            <MButton @click="hide">
                {{ __('common:actions.cancel') }}
            </MButton>
            <MButton
                v-if="filteredWeekTemplates.length"
                :loading="saveLoading"
                variant="primary"
                @click="!saveLoading ? useWeek() : null"
            >
                {{ __('common:actions.apply') }}
            </MButton>
        </template>
    </MModal>
</template>

<script lang="ts">
    import {Epoch} from '@meekohq/lumos';
    import {defineComponent, type PropType, toRef} from 'vue';

    import FeatureFlag from '@/modules/app/components/atoms/FeatureFlag.vue';
    import useEpoch from '@/modules/app/composables/useEpoch';
    import type {ModalType} from '@/modules/app/composables/useModal.ts';
    import InvalidEventErrors from '@/modules/human-resources/apply-template/infrastructure/components/InvalidEventErrors.vue';
    import FailedEventsAlert from '@/modules/human-resources/components/calendar/CalendarTemplate/FailedEventsAlert.vue';
    import SelectWeeks from '@/modules/human-resources/components/calendar/CalendarTemplate/SelectWeeks.vue';
    import EventModal from '@/modules/human-resources/components/calendar/EventModal.vue';
    import EventTotals from '@/modules/human-resources/components/calendar/EventTotals.vue';
    import Line from '@/modules/human-resources/components/calendar/Line.vue';
    import useWeekTemplate from '@/modules/human-resources/composables/calendar/useWeekTemplate';
    import type StaffModel from '@/modules/human-resources/models/StaffModel';
    import type TemplatePlanningModel from '@/modules/human-resources/models/TemplatePlanningModel.ts';
    import Hours from '@/modules/legacy/components/Modules/PlanningGenerator/Hours.vue';
    import StaffFinder from '@/modules/request/components/Teams/StaffFinder.vue';

    export default defineComponent({
        components: {
            InvalidEventErrors,
            FailedEventsAlert,
            FeatureFlag,
            StaffFinder,
            Hours,
            PlanningGenerator: Line,
            EventModal,
            EventTotals,
            SelectWeeks,
        },
        props: {
            staff: {
                type: Object as PropType<StaffModel>,
                required: true,
            },
            modal: {
                type: Object as PropType<ModalType>,
                required: true,
            },
        },
        setup(props) {
            const {fromMoment} = useEpoch();
            const {
                onShown,
                onHide,
                opening,
                closing,
                daysOfWeek,
                filteredWeekTemplates,
                selectedWeekTemplate,
                weekTemplatesLoading,
                calendarLoading,
                importTemplatesFromStaff,
                selectedWeeks,
                saveLoading,
                deleteRealEvents,
                invalidEventErrors,
                failedEvents,
                useWeek,
                events,
                totalWeekPlannings,
                totalPlannings,
                selectedEvent,
                modalEditEvent,
                newEvent,
                onEventAdded,
                editEvent,
                onEventDeleted,
            } = useWeekTemplate(toRef(props, 'modal'), props.staff, undefined, undefined, false);

            const onHidden = function () {
                onHide();
            };

            function selectTemplate(template: TemplatePlanningModel, onSelect?: () => void) {
                selectedWeekTemplate.value = template;
                if (onSelect) {
                    onSelect();
                }
            }

            return {
                Epoch,
                fromMoment,
                onShown,
                onHide,
                selectedWeeks,
                saveLoading,
                deleteRealEvents,
                invalidEventErrors,
                failedEvents,
                useWeek,
                opening,
                closing,
                daysOfWeek,
                filteredWeekTemplates,
                selectedWeekTemplate,
                selectTemplate,
                weekTemplatesLoading,
                calendarLoading,
                importTemplatesFromStaff,
                events,
                totalWeekPlannings,
                totalPlannings,
                selectedEvent,
                modalEditEvent,
                newEvent,
                onEventAdded,
                editEvent,
                onEventDeleted,
                onHidden,
            };
        },
    });
</script>
