<script setup lang="ts">
    import type {Collection} from '@meekohq/lumos';
    import {collect} from '@meekohq/lumos';
    import {computed, onMounted, ref, useTemplateRef} from 'vue';

    import useManager from '@/modules/app/composables/useManager.ts';
    import type {ModalType} from '@/modules/app/composables/useModal.ts';
    import __ from '@/modules/app/utils/i18n-facade.ts';
    import JobForm from '@/modules/human-resources/components/job/Forms/JobForm.vue';
    import JobModel from '@/modules/human-resources/models/JobModel.ts';
    import JobOrganizationPivot from '@/modules/human-resources/models/JobOrganizationPivot.ts';
    import useNotification from '@/modules/meeko-ui/composables/useNotification.ts';
    import OrganizationModel from '@/modules/organization/models/OrganizationModel.ts';

    const props = defineProps<{
        modal: ModalType;
        defaultName?: string;
    }>();

    const emit = defineEmits(['created', 'attachJobToOrga']);

    const isLoading = ref(false);
    const formRef = useTemplateRef('form');

    const job = ref(new JobModel());
    const everyJobs = ref<Collection<JobModel>>(collect());
    const pivots = ref<Collection<JobOrganizationPivot>>(collect());
    const organizations = ref<Collection<OrganizationModel>>(collect());

    const {activeOrganization} = useManager();

    const organizationJobs = computed(() => {
        return pivots.value
            .groupBy<JobOrganizationPivot>((pivot: JobOrganizationPivot) => pivot.attributes.organization_id)
            .get(activeOrganization.value.getKey(), collect())
            ?.map((pivot: JobOrganizationPivot) => everyJobs.value.where('id', pivot.getKey()).first());
    });

    const inOtherOrganizationJobs = computed(() => {
        return everyJobs.value
            .reject((item: JobModel) => {
                // reject every job that is already present in active organization
                const job = organizationJobs.value.where('id', '===', item.getKey()).first();

                return item === job;
            })
            .filter((item: JobModel) => {
                // return job with same name and not archived
                const isArchived = !!item.attributes.archived_at;

                return item.attributes.name === job.value.attributes.name && !isArchived;
            })
            .map((item: JobModel) => {
                // return job with his organizations to pass in the template to display organization's name where the job is
                return {
                    jobItem: item,
                    organizationsItem: fetchOrganization(item),
                };
            });
    });

    async function onShow() {
        job.value = new JobModel();
        job.value.attributes.account_id = activeOrganization.value.attributes.account_id;

        if (props.defaultName) {
            job.value.attributes.name = props.defaultName;
        }

        pivots.value = await JobOrganizationPivot.query().get();

        everyJobs.value = await JobModel.query().with(new JobModel().contracts()).get();
    }

    function onJobCreated(job: JobModel) {
        props.modal.hide();
        emit('created', job);
        useNotification().success(__('hr:job_created_successfully'));
    }

    function onAttachJobToOrga(job: JobModel) {
        props.modal.hide();
        emit('attachJobToOrga', job);
        useNotification().success(__('hr:job_linked_successfully'));
    }

    function fetchOrganization(job: JobModel) {
        const filteredPivots = pivots.value.filter(
            (pivot: JobOrganizationPivot) => pivot.attributes.job_id === job.getKey()
        );

        return filteredPivots.map((pivot: JobOrganizationPivot) => {
            return organizations.value.first(
                (organization: OrganizationModel) => organization.getKey() === pivot.attributes.organization_id
            );
        });
    }

    onMounted(async () => {
        organizations.value = await OrganizationModel.query().get();
    });
</script>

<template>
    <MModal
        :header-title="__('hr:add_job')"
        :modal="modal"
        size="sm"
        @show="onShow"
    >
        <JobForm
            ref="form"
            :job-model="job"
            @loading="isLoading = $event"
            @saved="onJobCreated"
        />
        <div v-if="inOtherOrganizationJobs.count()">
            <MSmall class="tw-mt-1 tw-block">
                <span v-html="__('hr:job_exist_on_another_organization', {name: jobModel.attributes.name})" />
            </MSmall>
            <div class="tw-mt-4">
                <ul>
                    <div
                        v-for="({jobItem, organizationsItem}, index) in inOtherOrganizationJobs"
                        :key="index"
                        class="tw-flex tw-flex-col tw-items-start tw-gap-2"
                    >
                        <MButton
                            variant="link"
                            @click="onAttachJobToOrga(jobItem)"
                        >
                            {{ __('hr:add_job') }}
                            <CBadge>
                                {{ jobItem.attributes.name }}
                            </CBadge>
                        </MButton>
                        <div>
                            <template v-if="organizationsItem.count() > 1">
                                <span class="tw-text-base">{{ __('common:exists_on') }}</span>
                                <MTooltip placement="bottom-start">
                                    <strong class="tw-text-base">
                                        {{ __('common:organization_with_count', {count: organizationsItem.count()}) }}
                                    </strong>
                                    <template #content>
                                        <ul>
                                            <div class="tw-mb-2 tw-text-base">
                                                {{ __('common:list_of_organizations') }} :
                                            </div>
                                            <li
                                                v-for="(organizationItem, i) in organizationsItem"
                                                :key="i + organizationItem.attributes.name"
                                            >
                                                - {{ organizationItem.attributes.name }}
                                            </li>
                                        </ul>
                                    </template>
                                </MTooltip>
                            </template>
                            <template v-else-if="organizationsItem.count() === 1">
                                <div>
                                    <span class="tw-text-base">
                                        {{ __('common:from') }}
                                        <strong class="tw-text-base">
                                            {{ organizationsItem.first().attributes.name }}
                                        </strong>
                                    </span>
                                </div>
                            </template>
                            <template v-else>
                                <span
                                    class="tw-text-base"
                                    v-html="__('common:from_account')"
                                />
                            </template>
                        </div>
                    </div>
                </ul>
            </div>
        </div>
        <template #footer="{hide}">
            <MButton
                class="tw-ml-auto"
                @click="hide"
            >
                {{ __('common:actions.close') }}
            </MButton>
            <MButton
                class="tw-ml-2"
                :loading="isLoading"
                variant="primary"
                @click="formRef.save()"
            >
                {{ __('common:actions.save') }}
            </MButton>
        </template>
    </MModal>
</template>
