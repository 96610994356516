<script setup lang="ts">
    import {useTemplateRef} from 'vue';

    import useAuth from '@/modules/app/composables/useAuth';
    import useModal from '@/modules/app/composables/useModal.ts';
    import JobsList from '@/modules/human-resources/components/job/List/JobsList.vue';
    import CreateJobModal from '@/modules/human-resources/components/job/Modals/CreateJobModal.vue';
    import type JobModel from '@/modules/human-resources/models/JobModel.ts';

    const jobListRef = useTemplateRef('job-list');

    const {legacyUser: user} = useAuth();

    function showCreateModal() {
        useModal({
            component: CreateJobModal,
            props: {},
            listeners: () => ({
                created: job => {
                    onJobCreated(job);
                },
                attachJobToOrga: job => {
                    onAttachJobToOrga(job);
                },
            }),
        }).show();
    }

    function onJobCreated(job: JobModel) {
        jobListRef.value.addToResult(job);
    }

    function onAttachJobToOrga(job: JobModel) {
        jobListRef.value.attachJobToOrga(job);
    }

    function importJobs() {
        jobListRef.value.importJobs();
    }
</script>

<template>
    <CVStack gap="4">
        <CInline
            align-x="between"
            align-y="baseline"
        >
            <MHeading level="h3">
                {{ __('hr:job_other') }}
            </MHeading>
            <CInline space="sm">
                <MTooltip
                    v-if="user.nurseries.length > 1"
                    :label="__('common:import_from_another_organization')"
                >
                    <MButton @click="importJobs">
                        <template #left-icons>
                            <FontAwesomeIcon icon="fa-solid fa-download" />
                        </template>
                        {{ __('common:actions.import') }}
                    </MButton>
                </MTooltip>
                <MButton
                    :icon-plus="true"
                    variant="primary"
                    @click="showCreateModal"
                >
                    {{ __('hr:add_job') }}
                </MButton>
            </CInline>
        </CInline>

        <JobsList ref="job-list" />
    </CVStack>
</template>
