import {ref} from 'vue';

import useAuth from '@/modules/app/composables/useAuth';
import StaffModel from '@/modules/human-resources/models/StaffModel';

const userStaff = ref<StaffModel>();
export default function () {
    const {user} = useAuth();

    async function retrieveUserStaff() {
        if (userStaff.value) {
            return userStaff;
        }

        if (user.value.attributes.email) {
            userStaff.value = await StaffModel.query().where('email', user.value.attributes.email).first();
        }

        return userStaff;
    }

    return {
        user,
        userStaff,
        retrieveUserStaff,
    };
}
